<div class="row">
    <a class="pull-left"><h4>{{incomingQuestion.getLabel().toString().toUpperCase()}}, Settings</h4></a>
    <a class="pull-right" [matDialogClose]="true" [ngStyle] = "{'cursor':'pointer'}"><i class="material-icons">close</i></a><br><br>
</div>
<div style="margin-top: 20px">
    <mat-tab-group mat-align-tabs="center">
        <mat-tab label="All">
            <mat-accordion [multi]="true">
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 28">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                stokeColor
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('stoke_color') ? incomingQuestion.getSettings()['stoke_color'] : ''"
                                           (input)="setInputSettings($event, 'stoke_color')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel >
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Template
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('template') ? incomingQuestion.getSettings()['template'] : ''"
                                           (input)="setInputSettings($event, 'template')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Src
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('src') ? incomingQuestion.getSettings()['src'] : ''"
                                           (input)="setInputSettings($event, 'src')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Document Title
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('document_title') ? incomingQuestion.getSettings()['document_title'] : ''"
                                           (input)="setInputSettings($event, 'document_title')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Photo
                            </td>
                            <td valign="top">
                                <!--                        <button mat-mini-fab><i ngClass="material-icons photo_camera"></i></button>-->
                                <div ngClass="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <img [src]="incomingQuestionSettings.getSrc()"
                                         [alt]="'img'"
                                         (click)="img.click()"
                                         (dblclick)="incomingQuestionSettings.setSrc('')"
                                         ngClass="mdl-shadow--2dpx"
                                         [ngStyle]="{'max-width':'100px','max-height':'150px'}"/>
                                </div>

                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Column
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('column') ? incomingQuestion.getSettings()['column'] : ''"
                                           (input)="setInputSettings($event, 'column')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="
            incomingQuestion.getControlId() === 5  ||
            incomingQuestion.getControlId() === 26 ||
            incomingQuestion.getControlId() === 20 ||
            incomingQuestion.getControlId() === 30 ||
            incomingQuestion.getControlId() === 31">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                kpi
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('target') ? incomingQuestion.getSettings()['target'] : ''"
                                           (input)="setInputSettings($event, 'target')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Alias
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <textarea type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('alias') ? incomingQuestion.getSettings()['alias'] : ''"
                                           (input)="setInputSettings($event, 'alias')"
                                    >
                                    </textarea>
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-tab-group>
                                <mat-tab label="{{option[strings.getLabel()]}}" *ngFor="let option of incomingQuestion.getOptions()"><br><br>
                                    <mat-tab-group>
                                        <mat-tab label="Marketing"><br><br>
                                            <mat-accordion [multi]="false">
                                                <mat-expansion-panel [expanded]="true">
                                                    <div style="margin-top: 10px">
                                                        <table>
                                                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                                                Selling price
                                                            </td>
                                                            <td valign="top">
                                                                <mat-form-field  class="pull-right" appearance="outline">
                                                                    <mat-label></mat-label>
                                                                    <input type="text"
                                                                           placeholder=""
                                                                           aria-label = "Number"
                                                                           matInput
                                                                           autocomplete="off"
                                                                           [ngStyle]="{'text-align':'center'}"
                                                                           [(ngModel)]="option.settings[strings.getSellingPrice()]"
                                                                    />
                                                                </mat-form-field>
                                                            </td>
                                                        </table>
                                                    </div>
                                                </mat-expansion-panel>
                                            </mat-accordion>
                                        </mat-tab>
                                    </mat-tab-group>
                                </mat-tab>
                        </mat-tab-group>
<!--                        <table class="headline2">-->
<!--                            <tr>-->
<!--                                <td>Daily kpi</td>-->
<!--                            </tr>-->
<!--                            <tr *ngFor="let option of incomingQuestion.getOptions()">-->
<!--                                <td [ngStyle]="{'width':'100%'}" valign="top">-->
<!--                                    {{option.label}}-->
<!--                                </td>-->
<!--                                <td title="label" *ngIf="service.parseFloat(incomingQuestion.getControlId())===3">-->
<!--                                    <mat-slide-toggle-->
<!--                                            [checked]="option.settings.hasOwnProperty('label') && service.parseFloat(option.settings.label) === 1"-->
<!--                                            (change)="$event.checked ? option.settings.label = 1 : option.settings.label = 0; update(option)">-->
<!--                                    </mat-slide-toggle>-->
<!--                                </td>-->
<!--                                <td title="lock">-->
<!--                                    <mat-slide-toggle-->
<!--                                            [checked]="option.settings.hasOwnProperty('lock') && service.parseFloat(option.settings.lock) === 1"-->
<!--                                            (change)="$event.checked ? option.settings.lock = 1 : option.settings.lock = 0; update(option)">-->
<!--                                    </mat-slide-toggle>-->
<!--                                </td>-->
<!--                                <td valign="top">-->
<!--                                    <mat-form-field  class="pull-right" appearance="outline">-->
<!--                                        <mat-label></mat-label>-->
<!--                                        <input type="text"-->
<!--                                               placeholder=""-->
<!--                                               aria-label = "Number"-->
<!--                                               matInput-->
<!--                                               autocomplete="off"-->
<!--                                               [ngStyle]="{'text-align':'center'}"-->
<!--                                               [(ngModel)]="option.settings.target"-->
<!--                                        />-->
<!--                                    </mat-form-field>-->
<!--                                </td>-->
<!--                                <td valign="top" [ngStyle]="{'width':'50px'}">-->
<!--                                    <mat-form-field  class="pull-right" appearance="outline">-->
<!--                                        <mat-label></mat-label>-->
<!--                                        <input type="text"-->
<!--                                               placeholder=""-->
<!--                                               aria-label = "Number"-->
<!--                                               list="lists"-->
<!--                                               matInput-->
<!--                                               autocomplete="off"-->
<!--                                               [ngStyle]="{'text-align':'center'}"-->
<!--                                               [(ngModel)]="option.settings.target_for"-->
<!--                                        />-->
<!--                                        <i class="material-icons pull-right">keyboard_arrow_down</i>-->
<!--                                        <datalist id="lists">-->
<!--                                            <option value="{{suggestion.name}}" *ngFor="let suggestion of this.service.app.data.ambassadors.data">-->
<!--                                        </datalist>-->
<!--                                    </mat-form-field>-->
<!--                                </td>-->
<!--                                <td valign="top" title="alias">-->
<!--                                    <mat-form-field  class="pull-right" appearance="outline">-->
<!--                                        <mat-label></mat-label>-->
<!--                                        <input type="text"-->
<!--                                               placeholder=""-->
<!--                                               aria-label = "Number"-->
<!--                                               matInput-->
<!--                                               autocomplete="off"-->
<!--                                               [ngStyle]="{'text-align':'center'}"-->
<!--                                               [(ngModel)]="option.settings.alias"-->
<!--                                        />-->
<!--                                    </mat-form-field>-->
<!--                                </td>-->
<!--                                <td valign="top">-->
<!--                                    <mat-form-field  class="pull-right" appearance="outline">-->
<!--                                        <mat-label></mat-label>-->
<!--                                        <input type="text"-->
<!--                                               placeholder=""-->
<!--                                               aria-label = "Number"-->
<!--                                               matInput-->
<!--                                               autocomplete="off"-->
<!--                                               [ngStyle]="{'text-align':'center'}"-->
<!--                                               [(ngModel)]="option.settings.description"-->
<!--                                        />-->
<!--                                    </mat-form-field>-->
<!--                                </td>-->
<!--                                <td valign="top">-->
<!--                                    <img [src]="option.settings.hasOwnProperty('src') ? option.settings.src : ''"-->
<!--                                         [alt]="'img'"-->
<!--                                         (click)="flag=1;incomingOptionSelected.setAttributes(option);img.click()"-->
<!--                                         (dblclick)="option.settings.src = ''"-->
<!--                                         ngClass="mdl-shadow--2dp img-cricle"-->
<!--                                         [ngStyle]="{'width':'100px','height':'100px'}"/>-->
<!--                                </td>-->
<!--                            </tr>-->
<!--                        </table>-->
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 10">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Maximum value
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.conditions.hasOwnProperty('maximum_value') ? incomingQuestion.getConditions()['maximum_value'] : ''"
                                           (keydown)="service.validateNumber($event)"
                                           (input)="assignInputCondition($event, 'maximum_value')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 10">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Digits
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngStyle]="{'text-align':'center'}"
                                           [ngModel]="incomingQuestion.attributes.conditions.hasOwnProperty('digits') ? incomingQuestion.getConditions()['digits'] : ''"
                                           (keydown)="service.validateNumber($event)"
                                           (input)="assignInputCondition($event, 'digits')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 10">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Minimum value
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngModel]="incomingQuestion.attributes.conditions.hasOwnProperty('minimum_value') ? incomingQuestion.getConditions()['minimum_value'] : ''"
                                           [ngStyle]="{'text-align':'center'}"
                                           (keydown)="service.validateNumber($event)"
                                           (input)="assignInputCondition($event, 'minimum_value')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" >
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> Is Scanning ?</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingQuestionSettings.getAttributes().hasOwnProperty('is_scanning') && incomingQuestionSettings.getIsScanning() === 1"
                                (change)="($event.checked ? incomingQuestionSettings.setIsScanning(1) : incomingQuestionSettings.setIsScanning(0))"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> is Qrcode ?</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingQuestionSettings.getAttributes().hasOwnProperty('is_qrcode') && incomingQuestionSettings.getIsQrcode() === 1"
                                (change)="($event.checked ? incomingQuestionSettings.setIsQrcode(1) : incomingQuestionSettings.setIsQrcode(0))"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> is Banner ?</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingQuestionSettings.getAttributes().hasOwnProperty('banner') && incomingQuestionSettings.getBanner() === 1"
                                (change)="($event.checked ? incomingQuestionSettings.setBanner(1) : incomingQuestionSettings.setBanner(0))"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 5">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> is Menu ?</span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingQuestionSettings.getAttributes().hasOwnProperty('is_menu') && incomingQuestionSettings.getIsMenu() === 1"
                                (change)="($event.checked ? incomingQuestionSettings.setIsMenu(1) : incomingQuestionSettings.setIsMenu(0))"
                        >
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 10">
                    <div [ngStyle]="{'margin-top': '10px'}">
                        Integer
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingQuestion.getConditions().hasOwnProperty('integer') && incomingQuestion.getConditions().integer === 1"
                                (change)="$event.checked ? incomingQuestion.getConditions().integer = 1 : incomingQuestion.getConditions().integer = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 10">
                    <div [ngStyle]="{'margin-top': '10px'}">
                        Distinct
                        <mat-checkbox
                                class="pull-right"
                                #distinctElm
                                [checked]="incomingQuestion.getSettings().hasOwnProperty('distinct') && incomingQuestion.getSettings().distinct === 1"
                                (change)="$event.checked ? incomingQuestion.getSettings().distinct = 1 : incomingQuestion.getSettings().distinct = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 10">
                    <div [ngStyle]="{'margin-top': '10px'}">
                        Comma
                        <mat-checkbox
                                class="pull-right"
                                #commaElm
                                [checked]="incomingQuestion.getConditions().hasOwnProperty('comma') && incomingQuestion.getConditions().comma === 1"
                                (change)="$event.checked ? incomingQuestion.getConditions().comma = 1 : incomingQuestion.getConditions().comma = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="
            incomingQuestion.getControlId() === 26 ||
            incomingQuestion.getControlId() === 30 ||
            incomingQuestion.getControlId() === 31">
                    <div [ngStyle]="{'margin-top': '10px'}">
                        Order
                        <mat-checkbox
                                class="pull-right"
                                #orderElm
                                [checked]="incomingQuestion.getSettings().hasOwnProperty('is_order') && incomingQuestion.getSettings().is_order === 1"
                                (change)="$event.checked ? incomingQuestion.getSettings().is_order = 1 : incomingQuestion.getSettings().is_order = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="incomingQuestion.getControlId() === 10">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Decimal Places
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngModel]="incomingQuestion.attributes.conditions.hasOwnProperty('decimal_places') ? incomingQuestion.getConditions()['decimal_places'] : ''"
                                           [ngStyle]="{'text-align':'center'}"
                                           (keydown)="service.validateNumber($event)"
                                           (input)="assignInputCondition($event, 'decimal_places')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true" *ngIf="
           incomingQuestion.getControlId() === 1 ||
           incomingQuestion.getControlId() === 16">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Text Size
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('text_size') ? incomingQuestion.getSettings()['text_size'] : ''"
                                           [ngStyle]="{'text-align':'center'}"
                                           (keydown)="service.validateNumber($event)"
                                           (input)="setInputSettings($event, 'text_size')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td [ngStyle]="{'width':'100%'}" valign="top">
                                Default
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('_default') ? incomingQuestion.getSettings()['_default'] : ''"
                                           [ngStyle]="{'text-align':'center'}"
                                           (input)="setInputSettings($event, '_default')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        Stockable
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingQuestion.getSettings().hasOwnProperty('stock') && incomingQuestion.getSettings().stock === 1"
                                (change)="$event.checked ? incomingQuestion.getSettings().stock = 1 : incomingQuestion.getSettings().stock = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <table>
                            <td class="headline2"
                                [ngStyle]="{'width':'100%'}" valign="top">
                                Bottle
                            </td>
                            <td valign="top">
                                <mat-form-field  class="pull-right" appearance="outline">
                                    <mat-label></mat-label>
                                    <input type="text"
                                           placeholder=""
                                           aria-label = "Number"
                                           matInput
                                           autocomplete="off"
                                           [ngModel]="incomingQuestion.attributes.settings.hasOwnProperty('bottle') ? incomingQuestion.getSettings()['bottle'] : ''"
                                           [ngStyle]="{'text-align':'center'}"
                                           (input)="setInputSettings($event, 'bottle')"
                                    />
                                </mat-form-field>
                            </td>
                        </table>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true"
                                     *ngIf="incomingQuestion.getControlId() === 4 ||
                             incomingQuestion.getControlId() === 5 ||
                             incomingQuestion.getControlId() === 27">
                    <div style="margin-top: 10px">
                        Countries
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingQuestion.getConditions().hasOwnProperty('countries') && incomingQuestion.getConditions().countries === 1"
                                (change)="$event.checked ? incomingQuestion.getConditions().countries = 1 : incomingQuestion.getConditions().countries = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true"
                                     *ngIf="incomingQuestion.getControlId() === 4 ||
                             incomingQuestion.getControlId() === 5 ||
                             incomingQuestion.getControlId() === 27">
                    <div style="margin-top: 10px">
                        Regions
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingQuestion.getConditions().hasOwnProperty('regions') && incomingQuestion.getConditions().regions === 1"
                                (change)="$event.checked ? incomingQuestion.getConditions().regions = 1 : incomingQuestion.getConditions().regions = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true"
                                     *ngIf="incomingQuestion.getControlId() === 4 ||
                             incomingQuestion.getControlId() === 5 ||
                             incomingQuestion.getControlId() === 27">
                    <div style="margin-top: 10px">
                        Districts
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingQuestion.getConditions().hasOwnProperty('districts') && incomingQuestion.getConditions().districts === 1"
                                (change)="$event.checked ? incomingQuestion.getConditions().districts = 1 : incomingQuestion.getConditions().districts = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true"
                                     *ngIf="incomingQuestion.getControlId() === 4 ||
                             incomingQuestion.getControlId() === 5 ||
                             incomingQuestion.getControlId() === 27">
                    <div style="margin-top: 10px">
                        Wards
                        <mat-checkbox
                                class="pull-right"
                                [checked]="incomingQuestion.getConditions().hasOwnProperty('wards') && incomingQuestion.getConditions().wards === 1"
                                (change)="$event.checked ? incomingQuestion.getConditions().wards = 1 : incomingQuestion.getConditions().wards = 0"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        Allow add others
                        {{this.incomingQuestion.attributes.hasOwnProperty('add_others') ? incomingQuestion.getAddOthers() : ''}}
                        <mat-checkbox
                                class="pull-right"
                                #addOthersElement
                                [checked]="this.incomingQuestion.attributes.hasOwnProperty('add_others') && this.incomingQuestion.getAddOthers() === 1"
                                (change)="$event.checked ? this.incomingQuestion.setAddOthers(1) : this.incomingQuestion.setAddOthers(0)"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel
                        [expanded]="true">
                    <div style="margin-top: 10px">
                        Plus one ?
                        <mat-checkbox
                                class="pull-right"
                                #isAutoNumber
                                [checked]="this.incomingQuestionSettings.attributes.hasOwnProperty(strings.getPlusOne()) && this.incomingQuestionSettings.getPlusOne() === 1"
                                (change)="$event.checked ? this.incomingQuestionSettings.setPlusOne(1) : this.incomingQuestionSettings.getPlusOne(0)"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel
                        *ngIf="this.service.parseFloat(incomingQuestion.getControlId()) === 10"
                        [expanded]="true">
                    <div style="margin-top: 10px">
                        is Auto Number ?
                        <mat-checkbox
                                class="pull-right"
                                #isAutoNumber
                                [checked]="this.incomingQuestionSettings.attributes.hasOwnProperty('is_auto_number') && this.incomingQuestionSettings.getIsAutoNumber() === 1"
                                (change)="$event.checked ? this.incomingQuestionSettings.setIsAutoNumber(1) : this.incomingQuestionSettings.getIsAutoNumber(0)"
                        >
                        </mat-checkbox>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <a mat-flat-button class="defaultColor pull-left"  (click)="import.click()">Import options</a>
                        <a mat-flat-button class="defaultColor pull-right" (click)="exportOptions()">Export</a>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <div style="margin-top: 10px">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label>Filter By</mat-label>
                                <input type="text"
                                       #filterByQuestionElement
                                       (input)="incomingQuestion.setReference('')"
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       [formControl] = "filterByControl"
                                       [matAutocomplete] = "filterByQuestion"/>
                                <mat-autocomplete #filterByQuestion = "matAutocomplete"
                                                  (optionSelected)="onQuestionSelectionChanged($event, 'reference')"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let question of filteredQuestionsOptions | async; let questionOf = index" [value]= "question" >
                                        {{question.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.isNull(filterByQuestionElement.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true"
                                     *ngIf="
                              this.service.parseFloat(incomingQuestion.getControlId()) === 26 ||
                             this.service.parseFloat(incomingQuestion.getControlId()) === 10 ||
                             this.service.parseFloat(incomingQuestion.getControlId()) === 30 ||
                             this.service.parseFloat(incomingQuestion.getControlId() === 31)">
                    <div style="margin-top: 10px">
                        <div style="margin-top: 10px">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label>Item</mat-label>
                                <input type="text"
                                       #itemId
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       (keyup.enter)="incomingQuestionSettings.setItem('');onInputTextChange(itemId.value)"
                                       [formControl] = "itemControl"
                                       [matAutocomplete] = "item"/>
                                <mat-autocomplete #item = "matAutocomplete"
                                                  (optionSelected)="incomingQuestionSettings.setItem($event.option.value.cntrl_num);"
                                                  [displayWith]="displayFnItem">
                                    <mat-option *ngFor="let item of filteredItemOptions | async; let incremental = index" [value]= "item" >

                                        <table>
                                            <tr>
                                                <td></td>
                                                <td> {{item.name}}</td>
                                            </tr>
                                        </table>
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.isNull(itemId.value) === false &&
                                 ((filteredQuestionsOptions | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="fill">
                            <mat-label>Position</mat-label>
                            <input  matInput
                                    type="text"
                                    [(ngModel)]="incomingQuestion.attributes.position"
                                    #positionElement>
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <mat-form-field  class="example-full-width" appearance="fill">
                            <mat-label>Selection At least</mat-label>
                            <input  matInput
                                    type="text"
                                    [(ngModel)]="incomingQuestion.attributes.atleast_optional_selections"
                                    #atleastOptionalSelectionsElement
                                    (keydown)="service.validateNumber($event)">
                        </mat-form-field>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <div style="margin-top: 10px">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label>Select Optional</mat-label>
                                <input type="text"
                                       #optionalElement
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       [formControl] = "optionalControl"
                                       [matAutocomplete] = "option"/>
                                <mat-autocomplete #option = "matAutocomplete"
                                                  (optionSelected)="onSelected($event,autoAggregatedFieldElement)"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let optional of filteredOptionalOptions | async; let optionalOf = index" [value]= "optional" >
                                        {{optional.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.isNull(optionalElement.value) === false &&
                                 ((filteredOptionalOptions | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <div style="margin-top: 10px">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label>Auto aggregated field</mat-label>
                                <input type="text"
                                       #autoAggregatedFieldElement
                                       (input)="incomingQuestion.setAutoAggregatedField('')"
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       [formControl] = "autoAgregatedFieldControl"
                                       [matAutocomplete] = "autoAgregatedField"/>
                                <mat-autocomplete #autoAgregatedField = "matAutocomplete"
                                                  (optionSelected)="onOptionalQuestionSelectionChanged($event, 'auto_aggregated_field')"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let optional of filteredAutoAggregatedFieldOptions | async; let optionalOf = index" [value]= "optional" >
                                        {{optional.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.isNull(autoAggregatedFieldElement.value) === false &&
                                 ((filteredAutoAggregatedFieldOptions | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div class="panel panel-default" [ngStyle]="{'width':'100%','border-top-size':'50px','border-top-color':'red'}">
                        <div class="panel-body">
                        </div>
                    </div>
                    <div style="margin-top: 10px">
                        <div style="margin-top: 10px">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label>Auto percentage field</mat-label>
                                <input type="text"
                                       #autoPercentageFieldElement
                                       (input)="incomingQuestion.setAutoPercentageField('')"
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       [formControl] = "autoPercentageFieldControl"
                                       [matAutocomplete] = "autoPercentageField"/>
                                <mat-autocomplete #autoPercentageField = "matAutocomplete"
                                                  (optionSelected)="onOptionalSelectionChanged($event, 'auto_percentage_field')"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let optional of filteredAutoPercentageFieldOptions | async; let optionalOf = index" [value]= "optional" >
                                        {{optional.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.isNull(autoPercentageFieldElement.value) === false &&
                                 ((filteredAutoPercentageFieldOptions | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <div style="margin-top: 10px">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label>Percentage field1</mat-label>
                                <input type="text"
                                       #percentageField1Element
                                       (input)="incomingQuestion.setPercentageField1('')"
                                       placeholder=""
                                       aria-label = "Number"
                                       matInput
                                       [formControl] = "percentageFieldControl1"
                                       [matAutocomplete] = "percentageField1"/>
                                <mat-autocomplete #percentageField1 = "matAutocomplete"
                                                  (optionSelected)="onOptionalSelectionChanged($event, 'percentage_field1')"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let optional of filteredPercentageField1Options | async; let optionalOf = index" [value]= "optional" >
                                        {{optional.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.isNull(percentageField1Element.value) === false &&
                                 ((filteredPercentageField1Options | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="true">
                    <div style="margin-top: 10px">
                        <div style="margin-top: 10px">
                            <mat-form-field  class="example-full-width" appearance="outline">
                                <mat-label>Percentage field2</mat-label>
                                <input type="text"
                                       #percentageField2Element
                                       placeholder=""
                                       (input)="incomingQuestion.setPercentageField2('')"
                                       aria-label = "Number"
                                       matInput
                                       [formControl] = "percentageFieldControl2"
                                       [matAutocomplete] = "percentageField2"/>
                                <mat-autocomplete #percentageField2 = "matAutocomplete"
                                                  (optionSelected)="onOptionalSelectionChanged($event, 'percentage_field2')"
                                                  [displayWith]="displayFn">
                                    <mat-option *ngFor="let optional of filteredPercentageField2Options | async; let optionalOf = index" [value]= "optional" >
                                        {{optional.label}}
                                    </mat-option>
                                </mat-autocomplete>
                                <h4 style="color: red; font-weight: normal"
                                    *ngIf="service.isNull(percentageField2Element.value) === false &&
                                 ((filteredPercentageField2Options | async) | json).length === 2">
                                    **---Not Found *---</h4>
                            </mat-form-field>
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-tab>
        <mat-tab label="Marketing">
            <mat-accordion [multi]="true">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <span class=""> Is this your distributor ? </span>
                        </mat-panel-title>
                        <mat-panel-description>
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="">
                        <mat-slide-toggle
                                class="pull-right"
                                [checked]="incomingQuestionSettings.getAttributes().hasOwnProperty(strings.getIsDistributor()) && incomingQuestionSettings.getIsDistributor() === 1"
                                (change)="($event.checked ? incomingQuestionSettings.setIsDistributor(1) : incomingQuestionSettings.setIsDistributor(0))">
                        </mat-slide-toggle>
                    </div>
                </mat-expansion-panel >
            </mat-accordion>
        </mat-tab>
    </mat-tab-group>
</div>
<input type="file" (change)="importOptions($event)" [ngStyle]="{'display':'none'}"  #import>
<input type="file" (change)="openImgDialog($event)" [ngStyle]="{'display':'none'}"  #img>
<div id="form"></div>
