import { NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {HeaderComponent} from './header/header.component';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {AboutComponent } from './about/about.component';
import {LoginComponent} from './login/login.component';
import {SitemapComponent} from './dashboard/sitemap/sitemap.component';
import {OverallComponent} from './dashboard/overall.component';
import {ClientsComponent} from './clients/clients.component';
import {FormsComponent} from './forms/forms.component';
import {CampaniesComponent} from './campanies/campanies.component';
import {CampaignsComponent} from './campaigns/campaigns.component';
import {TeamsComponent} from './teams/teams.component';
import {GetCntrlNumComponent} from './main/get-cntrl-num/get-cntrl-num.component';
import {AmbassadorsComponent} from './ambassadors/ambassadors.component';
import {EmployeesComponent} from './employees/employees.component';
import {PublicFormsComponent} from './forms/responses/feedback/feedback-forms.component';
import {ResponsesFormComponent} from './forms/responses/data/list/responses-form.component';
import {NavigationsFormComponent} from './forms/navigations-form/navigations-form.component';
import {SuccessfullSuggestionsFormsComponent} from './forms/suggestions/successfull-suggestions-forms/successfull-suggestions-forms.component';
import {HomeInternalSuggestionsComponent} from './forms/suggestions/internal/home-internal-suggestions/home-internal-suggestions.component';
import {SuggestionsComponent} from './suggestions/suggestions.component';
import {DepartmentsComponent} from './departments/departments.component';
import {SearchFormsComponent} from './forms/search/search-forms.component';
import {ListItemsComponent} from './items/list-items/list-items.component';
import {FormsAmbassadorComponent} from './ambassadors/forms-ambassador.component';
import {LivePhotoWallComponent} from './images/live-photo-wall/live-photo-wall.component';
import {UsersComponent} from './users/users.component';
import {OutletOrdersComponent} from './orders/outlet-orders/outlet-orders.component';
import {SearchCampaignsComponent} from './campaigns/campaigns/search-campaigns.component';
import {OpenCampaignsComponent} from './campaigns/open/open-campaigns.component';
import {MenusComponent} from './menus/menus.component';
import {RespondFormComponent} from './forms/responses/respond/respond-form.component';
import {CreateFormComponent} from './forms/create/create-form.component';
import {ManageTeamsComponent} from './teams/manage/manage-teams.component';
import {CreateCampaignComponent} from './campaigns/create/create-campaign.component';
import {ListCampaignComponent} from './campaigns/list/list-campaign.component';
import {BuildFormComponent} from './forms/build/build-form.component';
import {RightHeaderComponent} from './header/right-header/right-header.component';
import {PdfComponent} from './forms/pdf/pdf.component';
import {MapComponent} from './map/map.component';
import {VideosFormComponent} from './forms/videos/videos-form.component';
import {InventoriesComponent} from './inventories/inventories/inventories.component';
import {FilesFormComponent} from './forms/files/files-form.component';
import {AmbassadorResponsesComponent} from './ambassadors/responses/ambassador-responses.component';
import {Dashboard2FormComponent} from './forms/dashboard/dashboard2/dashboard2-form.component';
import {DashboardClientsComponent} from './clients/dashboard-clients/dashboard-clients.component';
import {Dashboard4FormComponent} from './forms/dashboard/4/dashboard4-form.component';
import {BrunchTeamsComponent} from './teams/brunches/brunch-teams.component';
import {RegionalsComponent} from './regionals/regionals.component';
import {HeaderJobinnv120Component} from './header/jobinnv1.2.0/header-jobinnv120.component';
import {ImagesFormComponent} from './forms/images/images-form.component';
import {OutletsComponent} from './outlets/outlets.component';
import {DashboardInvitesComponent} from './invites/dashboard/dashboard-invites.component';
import {LoginUiComponent} from './login/ui/login-ui.component';
import {EngagementOutletReportsComponent} from './reports/outlets/engagement-outlet-reports/engagement-outlet-reports.component';
import {AmbassadorsListComponent} from './ambassadors/list/ambassadors-list.component';
import {DateRangeNumberReportComponent} from './reports/range/date-range-number-report.component';
import {AmbassadorsFinalReportComponent} from './reports/final/ambassadors-final-report.component';
import {RoutesComponent} from './routes/routes.component';
import {QrcodeScanningInvitatationComponent} from './invitations/qrcode/scanning/qrcode-scanning-invitatation.component';
import {InviteeWelcomeScreenComponent} from './invitations/invitee-welcome-screen/invitee-welcome-screen.component';
import {ConfirmationInvititationsComponent} from './invitations/confirmation-invititations/confirmation-invititations.component';
import {ViewInvitationCardComponent} from './invitations/card/view-invitation-card/view-invitation-card.component';
import {RegistraHeaderComponent} from './header/registra/registra-header.component';
import {HomeRegistraComponent} from './home/registra/home-registra.component';
import {RegisterComponent} from './register/register.component';
import {ChannelPaymentsComponent} from './payments/channel-payments/channel-payments.component';
import {ChatbotResponsesComponent} from "./responses/chatbot/chatbot-responses.component";
import {ChatbottResponsesComponent} from "./responses/chatbott-responses/chatbott-responses.component";
import {AnnoucementEventsComponent} from "./events/annoucement-events/annoucement-events.component";
import {ExhibitorRegistrationEventsComponent} from "./events/registrations/exhibitor-registration-events/exhibitor-registration-events.component";
import {HomeServicesComponent} from "./services/home-services/home-services.component";
import {FormListsComponent} from "./forms/lists/form-lists/form-lists.component";
import {MapAmbassadorOrdersComponent} from "./ambassadors/partials/map/map-ambassador-orders.component";
import {OrdersComponent} from "./orders/orders.component";
import {ShipmentOrderComponent} from "./orders/shipment/shipment-order.component";
import {DashboardDistributorsComponent} from "./distributors/dashboard/dashboard-distributors.component";
import {RegisterAttendeeComponent} from "./invites/register/register-attendee.component";
import {WheelRafflesComponent} from "./raffles/wheel-raffles/wheel-raffles.component";
import {DistributorsComponent} from "./distributors/distributors.component";
import {RegisterEventsComponent} from "./events/registrations/attendee-registration-events/register-events.component";
import {DetailsAccountComponent} from "./events/registrations/accounts/details-account/details-account.component";

const routes: Routes = [
    { path: 'dashboard/sitemap' , component : SitemapComponent},
    { path: 'registra' , component : RegistraHeaderComponent,
        children : [
            {path : 'home'              , component: HomeRegistraComponent},
            {path: 'payments/channels'  , component : ChannelPaymentsComponent},
            
        ]
    },
    { path: 'us' , component : RightHeaderComponent,
            children : [
                 { path: 'app/forms/:id/dashboard3'       , component : Dashboard2FormComponent},
                 { path: 'app/forms/:id/images'           , component : ImagesFormComponent},
                 { path: 'app/forms/:id/responses'        , component : ResponsesFormComponent},
                 { path: 'app/forms/:id/ambassadors'      , component : AmbassadorsComponent},
                 { path: 'app/forms/:id/distributors/dashboard' , component : DashboardDistributorsComponent},
                 { path: 'app/forms/:id/questions/:questionId/responses' , component : ResponsesFormComponent},
                 { path: 'app/forms/:formId/questions/:questionId/inventories' , component : InventoriesComponent},
                 { path: 'app/forms/:id/questions/:questionId/labels/:label/responses' , component : ResponsesFormComponent},
            ]
    },
    // { path: 'app2' , component: HeaderJobinnv120Component,
    //     children : [
    //      {path : 'forms/:id/map' , component: MapFormComponent}
    //     ]
    // },
    { path: 'app' , component: HeaderComponent,
        children : [
            {path : 'home'                   , component : HomeComponent},
            { path: 'dashboard'              , component : OverallComponent},
            {path : 'about'                  , component : AboutComponent},
            {path:  'users'                  , component : UsersComponent},
            {path:  'clients'                , component : ClientsComponent},
            {path:  'forms'                  , component : FormsComponent},
            {path:  'surveys'                , component : FormsComponent},
            {path:  'campanies'              , component : CampaniesComponent},
            {path:  'campaigns'              , component : CampaignsComponent},
            {path:  'responses'              , component : ResponsesFormComponent},
            {path:  'regions'                , component : RegionalsComponent},
            {path:  'projects'               , component : CampaignsComponent},
            {path:  'forms/:id/items'        , component : ListItemsComponent},
            {path:  'teams'                  , component : TeamsComponent},
            {path:  'teams/:name/brunches'   , component : BrunchTeamsComponent},
            {path:  'employees'              , component : EmployeesComponent},
            {path:  'ambassadors'            , component : AmbassadorsComponent},
            {path:  'departments'            , component : DepartmentsComponent},
            {path:  'forms/:id/dashboard'    , component : Dashboard2FormComponent},
            {path:  'forms/:id/dashboard1'   , component : Dashboard2FormComponent},
            {path:  'forms/:id/dashboard2'   , component : Dashboard2FormComponent},
            {path:  'forms/:id/dashboard4'   , component : Dashboard4FormComponent},
            {path:  'forms/:id/teams'        , component : ManageTeamsComponent},
            {path:  'forms/:id/responses'    , component : ResponsesFormComponent},
            {path:  'forms/:id/map'          , component : MapComponent},
            {path:  'forms/:id/videos'       , component : VideosFormComponent},
            {path:  'forms/:id/files'        , component : FilesFormComponent},
            {path:  'forms/:id/invites'      , component : DashboardInvitesComponent},
            {path:  'forms/:id/respondents'  , component : AmbassadorsComponent},
            {path:  'forms/:id/outlets'      , component : OutletsComponent},
        ]},
    { path: 'wait..'                         , component : MainComponent, data: {routeName: 'wait'}},
    { path: 'app/suggestions'                , component : SuggestionsComponent},
    { path: 'app/forms/:id/ambassadors/list' , component : AmbassadorsListComponent},
    { path: 'app/reports/outlets/engagement' , component : EngagementOutletReportsComponent},
    { path: 'app/menus'                      , component : MenusComponent},
    { path: 'forms/search'                   , component : SearchFormsComponent},
    { path: 'app/forms/create'               , component : BuildFormComponent},
    { path: 'app/services'                   , component : HomeServicesComponent},
//  { path: 'public/forms/:id'               , component : PublicFormsComponent},
    { path: 'app/supervisors/:id/responses'  , component : DateRangeNumberReportComponent},
    { path: 'app/forms/:id/pdf'              , component : PdfComponent},
    { path:  'app/forms/:id/images3'         , component : ImagesFormComponent},
    { path: 'forms/:id/suggestions'          , component : PublicFormsComponent, data: {routeName: 'suggestions'}},
    { path: 'app/ambassadors/:name/forms'    , component : FormsAmbassadorComponent},
    { path: 'campaigns/search'               , component : SearchCampaignsComponent},
    { path: 'projects/search'                , component : SearchCampaignsComponent},
    { path: 'app/projects/create'            , component : CreateCampaignComponent},
    { path: 'app/events/:eventId/raffles'    , component : WheelRafflesComponent},
    { path: 'app/main/:cntrlnum/getrequest'  , component : GetCntrlNumComponent},
    { path: 'app/projects/open'              , component : OpenCampaignsComponent},
    { path: 'app/distributors'               , component : DistributorsComponent},
    { path: 'app/ambassadors/:id/responses'  , component : AmbassadorResponsesComponent},
    { path: 'app/projects/list'              , component : FormListsComponent},
    { path: 'app/forms/list'                 , component : FormListsComponent},
    { path: 'forms/:id/internal/suggestions' , component : HomeInternalSuggestionsComponent},
    { path: 'forms/suggestions/successfull'  , component : SuccessfullSuggestionsFormsComponent},
    { path: 'app/forms/create/:email/:pass'  , component : CreateFormComponent},
    { path: 'app/forms/:id/respond'          , component : RespondFormComponent},
    { path: 'app/forms/:id/feedback'         , component : RespondFormComponent},
 // { path: 'app/events/:id/register'        , component : RespondFormComponent},
    { path: 'app/forms/:id/live/photo/wall'  , component : ImagesFormComponent},
    {path:  'app/forms/:id/images'           , component : ImagesFormComponent},
    { path: 'login'                          , component : LoginUiComponent},
    { path: 'register'                       , component : RegisterComponent},
    { path: 'forms/:id/navigations'          , component : NavigationsFormComponent},
    { path: 'ambassadors/:id/map'            , component : MapAmbassadorOrdersComponent},
    { path: 'app/forms/:id/dashboard3'       , component : DashboardClientsComponent},
    { path: 'app/forms/:id/chatbot'          , component : ChatbotResponsesComponent},
    { path: 'app/invitations/:id/chatbot'    , component : ChatbotResponsesComponent},
    { path: 'app/forms/:id/routes'           , component : RoutesComponent},
    { path: 'app/invitee/welcome/screen'     , component : InviteeWelcomeScreenComponent},
    { path: 'app/orders'                     , component : OrdersComponent},
    { path: 'app2/forms/:id/map'             , component : MapComponent},
    { path: 'app/orders/:orderId/shipment'   , component : ShipmentOrderComponent},
    { path: 'app/events/:formId/register'    , component : RegisterEventsComponent},
    { path: 'app/events/:formId/accounts/details'       , component : DetailsAccountComponent},
    { path: 'app/events/:eventId/invitees/register'     , component : RegisterAttendeeComponent},
    { path: 'app/invitations/:formId/qrcode/scanning'   , component : QrcodeScanningInvitatationComponent},
    { path: 'app/events/:formId/annoucement'            , component : AnnoucementEventsComponent},
    { path: 'app/forms/:id/ambassadors/final/report'    , component : AmbassadorsFinalReportComponent},
    { path: 'app/invitations/:responseId/confirmation'  , component : ConfirmationInvititationsComponent},
    { path: 'app/customers/:outletId/:formId/orders'    , component : OutletOrdersComponent},
    { path: 'app/invitations/:responseId/:formId/card'  , component : ViewInvitationCardComponent},
    { path: '**'                             , component : MainComponent, data: {routeName: 'app'}},
    { path: '404'                            , redirectTo: '/wait..', pathMatch: 'full', data: {routeName: 'app'}},
    { path: ''                               , redirectTo: '/wait..', pathMatch: 'full', data: {routeName: 'app'}},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
