import {Component, ElementRef, Input, OnInit, ViewChild, OnDestroy, Inject} from '@angular/core';
import {FormService} from "../forms/form.service";
import {AppService} from "../app.service";
import {DialogEmployeesComponent} from "../employees/dialog-employees/dialog-employees.component";
import {EmployeesService} from "../employees/employees.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MatMenuTrigger} from "@angular/material/menu";
import {BroadcastChannelService} from '../broadcast-channel.service';
import {UserService} from "../users/user.service";
import {Parameters} from "../parameters";
import {NotifierService} from "angular-notifier";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {map, startWith} from "rxjs/internal/operators";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs";
import {PromptComponent} from '../prompt/prompt.component';
import {SettingsCriteriasDialogComponent} from "../criterias/settings/settings-criterias-dialog.component";
import {MessageDisplayComponent} from "../main/message/message-display.component";
import {EntityService} from "../entities/entity.service";
@Component({
    selector: 'app-settings-form',
    templateUrl: './settings-form.component.html',
    styleUrls: ['./settings-form.component.scss']
})
export class SettingsFormComponent implements OnInit, OnDestroy {
    @Input() form: any;
    @ViewChild('productElement') productElement: any;
    @ViewChild(MatMenuTrigger) contextMenu: any;
    appweekUrl = '../assets/json/days.json';
    appmonthUrl = '../assets/json/month.json';
    appweeksUrl  = '../assets/json/weeks.json';
    contextMenuPosition = { x: '0px', y: '0px' };
    isMultipleResponse: any = true;
    panelOpenState = false;
    flag: any = 0;
    employeedisplayedColumns: string[] = ['name', 'phone', 'email', 'location'];
    selectedEmployee: any;
    managers: any = [];
    incomingForm: any;
    timeoutStatus: any = false;
    strings: any;
    questionControl = new FormControl();
    ticketsControl = new FormControl();
    packagesControl = new FormControl();
    isPayedControl = new FormControl();
    outletChannelControl = new FormControl();
    isReceivingInvitationCardControl = new FormControl();
    isEmailControl = new FormControl();
    isWhatsappControl = new FormControl();
    attendeeNameControl = new FormControl();
    invitationCardEmailedControl = new FormControl();
    invitationCardSentThroughWhatsappControl = new FormControl();
    paymentMethodControl = new FormControl();
    responseChannelControl = new FormControl();
    bannerControl = new FormControl();
    contactPersonPhoneControl = new FormControl();
    attendeePicControl = new FormControl();
    contactPersonTitleControl = new FormControl();
    isInvitedByWhatsappControl = new FormControl();
    deliveredControl = new FormControl();
    contactControl = new FormControl();
    confirmationContentsControl = new FormControl();
    invitationCardControl = new FormControl();
    attendeeEmailControl = new FormControl();
    attendeePhoneControl = new FormControl();
    attendeeCompanyControl = new FormControl();
    attendeeSalutationControl = new FormControl();
    invoiceControl = new FormControl();
    attendeeJobPositionControl = new FormControl();
    isConfirmControl = new FormControl();
    isInvitedControl = new FormControl();
    addressControl = new FormControl();
    outletControl = new FormControl();
    coordinateControl = new FormControl();
    titleControl = new FormControl();
    attendeeJobTitleControl = new FormControl();
    dateControl = new FormControl();
    isAttendingControl = new FormControl();
    uniqidControl = new FormControl();
    stockItemControl = new FormControl();
    isCardSentControl = new FormControl();
    customReportControl = new FormControl();
    coreBusinessFieldControl = new FormControl();
    orderStatusControl = new FormControl();
    productCtrl = new FormControl();
    filteredQuestionsOptions: any;
    filteredOutletChannelQuestionsOptions: any;
    filteredDeliveredQuestionsOptions: any;
    filteredPaymentMethodQuestionsOptions: any;
    filteredInvoiceQuestionsOptions: any;
    filteredAttendeeJobPositionQuestionsOptions: any;
    filteredTicketsQuestionsOptions: any;
    filteredPackagesQuestionsOptions: any;
    filteredIsPayedQuestionsOptions: any;
    filteredAttendeeJobTitleQuestionsOptions: any;
    filteredAttendeeEmailQuestionsOptions: any;
    filteredAttendeePhoneQuestionsOptions: any;
    filteredInvitationCardQuestionsOptions: any;
    filteredAttendeePicQuestionsOptions: any;
    filteredAttendeeSalutationQuestionsOptions: any;
    filteredOrderStatusQuestionsOptions: any;
    filteredAttendeeCompanyQuestionsOptions: any;
    filteredAttendeeNameQuestionsOptions: any;
    filteredIsReceivingInvitationCardQuestionsOptions: any;
    filteredIsEmailQuestionsOptions: any;
    filteredQuestionsIsWhatsappOptions: any;
    filteredQuestionsAttendingOptions: any;
    filteredIsCardSentQuestionsOptions: any;
    filteredCoreBusinessFieldOptions: any;
    incomingflattenQuestions: any;
    incomingCriterias: any;
    incomingControl: any;
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filteredProductOptions: Observable<any>;
    allProductOptions: any = [];
    currentQuestionalProducts: any = [];
    incomingOption: any;
    outgoingRequest: any;
    incomingTeam: any;
    outgoingTeam: any;
    incomingQuestion: any;
    spinner: any = false;
    incomingCustomReportSelected: any;
    incomingCustomReportQuestionSelected: any;
    incomingCustomReport: any;
    incomingDistrict: any;
    outgoingDistrict: any;
    incomingOutlet: any;
    outgoingOutlet: any;
    outgoingUser: any;
    incomingUser: any;
    incomingCriteriaQuestionOption: any;
    charts: any = [];
    menuStatus: any = 1;
    incomingSettings: any;
    incomingAvatar: any;
    incomingCriteria: any;
    operators: any = [];
    subscription: any;
    incomingCriteriaSelected: any;
    outgoingQuestion: any;
    outgoingOption: any;
    incomingCriteriaQuestionSelected: any;
    ougoingCriteria: any;
    position2: any;
    position1: any;
    result: any;
    incomingResult: any;
    incomingRateOption: any;
    incomingRateOptionSelected: any;
    incomingPoweredBySelected: any;
    countries: any;
    week: any = [];
    month: any = [];
    regions: any = [];
    districts: any;
    incomingRegion: any;
    outgoingRegion: any;
    incomingCriteriaQuestion: any;
    customQuestions: any = [];
    constructor(
        public formService: FormService,
        public  service: AppService,
        private notifier: NotifierService,
        private entityService: EntityService,
        public broadcastChannel: BroadcastChannelService,
        private userService: UserService,
        private dialog: MatDialog,
        private employeeMetthods: EmployeesService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SettingsFormComponent>) {
        this.productElement = ElementRef;
        this.incomingForm = new Parameters();
        this.incomingDistrict = new Parameters();
        this.incomingCriteriaQuestion = new Parameters();
        this.outgoingDistrict = new Parameters();
        this.incomingOutlet = new Parameters();
        this.outgoingOutlet = new Parameters();
        this.outgoingTeam = new Parameters();
        this.incomingTeam = new Parameters();
        this.strings = new Parameters();
        this.outgoingUser = new Parameters();
        this.incomingUser = new Parameters();
        this.incomingCriteriaQuestionOption = new Parameters();
        this.incomingflattenQuestions = new Parameters();
        this.incomingOption  =  new Parameters();
        this.incomingCriterias = new Parameters();
        this.incomingPoweredBySelected = new Parameters();
        this.outgoingRequest = new Parameters();
        this.incomingRateOptionSelected = new Parameters();
        this.incomingRateOption = new Parameters();
        this.ougoingCriteria = new Parameters();
        this.incomingRegion = new Parameters();
        this.outgoingRegion = new Parameters();
        this.incomingQuestion = new Parameters();
        this.incomingResult = new Parameters();
        this.incomingCriteriaQuestionSelected = new Parameters();
        this.incomingCustomReportQuestionSelected = new Parameters();
        this.incomingCustomReport = new Parameters();
        this.outgoingQuestion =  new Parameters();
        this.outgoingOption =  new Parameters();
        this.incomingControl = new Parameters();
        this.incomingCriteriaSelected = new Parameters();
        this.incomingCriteria = new Parameters();
        this.incomingAvatar = new Parameters();
        this.incomingCustomReportSelected = new Parameters();
        this.incomingSettings = new Parameters();
        this.form = this.formService.getFormById(formService.formSelectedId);
        this.incomingForm.setAttributes(this.form);
        this.incomingCriteriaSelected.setAttributes({});
        this.incomingSettings.setAttributes({});
        this.incomingCustomReportSelected.setAttributes({});
        this.incomingCustomReportQuestionSelected.setAttributes({});
        this.incomingRateOptionSelected.setAttributes({});
        this.contextMenu = MatMenuTrigger;
        this.allProductOptions = this.incomingForm.getControls();
        this.strings.setAttributes(this.service.app.strings.params);
        this.filteredProductOptions = this.productCtrl.valueChanges.pipe(
            startWith(''),
            map((optional: string | null) => optional ? this._filterProduct(optional) : this.allProductOptions.slice()));
    }


    ngOnDestroy() {
        if(this.service.outgoingRegionsControl.attributes.hasOwnProperty('id') &&
           parseFloat(this.service.outgoingRegionsControl.getFormId()) === parseFloat(this.incomingForm.getId())){
            this.customQuestions.map((questionCntrlNum: any)=>{
                this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), questionCntrlNum), 1);});
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.outgoingRegionsControl.getCntrlNum()), 1);
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.outgoingDistrictsControl.getCntrlNum()), 1);
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.outgoingTeamsControl.getCntrlNum()), 1);
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.daysControl.getCntrlNum()), 1);
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.outgoingWeeksControl.getCntrlNum()), 1);
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.outgoingMonthControl.getCntrlNum()), 1);
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.outgoingUsersControl.getCntrlNum()), 1);
           this.incomingForm.getControls().splice(this.service.findIndex(this.incomingForm.getControls(), this.strings.getCntrlNum(), this.service.outgoingOutletsControl.getCntrlNum()), 1);
           this.service.outgoingRegionsControl.setAttributes({});
           this.service.outgoingDistrictsControl.setAttributes({});
           this.service.outgoingTeamsControl.setAttributes({});
           this.service.outgoingUsersControl.setAttributes({});
           this.service.outgoingOutletsControl.setAttributes({});
           this.service.daysControl.setAttributes({});
           this.service.outgoingWeeksControl.setAttributes({});
           this.service.outgoingMonthControl.setAttributes({});
        }this.subscription.unsubscribe();
        this.updateSettings();
    }

    setRegionsControl(){
        if(this.service.outgoingRegionsControl.attributes.hasOwnProperty('id') &&
            parseFloat(this.service.outgoingRegionsControl.getFormId()) === parseFloat(this.incomingForm.getId())){}else{
            this.service.outgoingRegionsControl.setOptions([]);
            this.service.outgoingRegionsControl.setLabel('Regions *');
            this.service.outgoingRegionsControl.setCntrlNum(this.service.random());
            this.service.outgoingRegionsControl.setControlId(5);
            this.service.outgoingRegionsControl.setId(this.service.random());
            this.service.outgoingRegionsControl.setFormId(this.incomingForm.getId());
            this.service.outgoingRegionsControl.setControl({id:5,text:'',name:''});
            this.service.app.data.regions.data.map((region: any) => {
                this.incomingRegion.setAttributes(Object.assign({},region));
                this.outgoingRegion.setAttributes({});
                this.outgoingRegion.setId(this.incomingRegion.getId());
                this.outgoingRegion.setRegionId(this.incomingRegion.getId());
                this.outgoingRegion.setPlaceId(this.incomingRegion.getPlaceId());
                this.outgoingRegion.setLabel(this.incomingRegion.getName());
                this.outgoingRegion.setCntrlNum(this.incomingRegion.getPlaceId());
                this.outgoingRegion.setFormControlId(this.service.outgoingRegionsControl.getId());
                this.service.outgoingRegionsControl.getOptions().push(this.outgoingRegion.getAttributes());
            }); this.incomingForm.getControls().push(this.service.outgoingRegionsControl.getAttributes());
        }

    }

    setDaysControl(){
        if(this.service.daysControl.attributes.hasOwnProperty('id') &&
            parseFloat(this.service.daysControl.getFormId()) === parseFloat(this.incomingForm.getId())){}else{
            this.service.daysControl.setOptions([]);
            this.service.daysControl.setLabel(this.strings.getDays() + ' *');
            this.service.daysControl.setCntrlNum(this.service.random());
            this.service.daysControl.setControlId(5);
            this.service.daysControl.setId(this.service.random());
            this.service.daysControl.setFormId(this.incomingForm.getId());
            this.service.daysControl.setControl({id:5,text:'',name:''});
            this.service.httpService( 'get', this.appweekUrl, {}, {ignoreLoadingBar : 'true', notify: 'false'},
                (days: any) => {
                    days.map((day: any, l: any) => {
                        let incomingDay: any = new Parameters();
                        incomingDay.setAttributes({});
                        incomingDay.setId(l);
                        incomingDay.setDayId(l);
                        incomingDay.setFormControlId(l);
                        incomingDay.setCntrlNum(this.service.random());
                        incomingDay.setLabel(day);
                        this.service.daysControl.getOptions().push(incomingDay.getAttributes());
                    });this.incomingForm.getControls().push(this.service.daysControl.getAttributes());
                },  (error: any) => {
                });
        }

    }


    setWeeksControl(){
        if(this.service.outgoingWeeksControl.attributes.hasOwnProperty('id') &&
            parseFloat(this.service.outgoingWeeksControl.getFormId()) === parseFloat(this.incomingForm.getId())){}else{
            this.service.outgoingWeeksControl.setOptions([]);
            this.service.outgoingWeeksControl.setLabel('Weeks *');
            this.service.outgoingWeeksControl.setCntrlNum(this.service.random());
            this.service.outgoingWeeksControl.setControlId(5);
            this.service.outgoingWeeksControl.setId(this.service.random());
            this.service.outgoingWeeksControl.setFormId(this.incomingForm.getId());
            this.service.outgoingWeeksControl.setControl({id:5,text:'',name:''});
            this.service.httpService( 'get', this.appweeksUrl, {}, {ignoreLoadingBar : 'true', notify: 'false'},
                (weeks: any) => {
                    weeks.map((week: any, l: any) => {
                        let ougoingWeeks: any = new Parameters();
                        ougoingWeeks.setAttributes(week);
                        ougoingWeeks.setId(l);
                        ougoingWeeks.setWeekId(l);
                        ougoingWeeks.setLabel(ougoingWeeks.getName());
                        ougoingWeeks.setCntrlNum(this.service.random());
                        ougoingWeeks.setFormControlId(this.service.outgoingWeeksControl.getId());
                        this.service.outgoingWeeksControl.getOptions().push(ougoingWeeks.getAttributes());
                    }); this.incomingForm.getControls().push(this.service.outgoingWeeksControl.getAttributes());
                },  (error: any) => {
                });

        }

    }

    setMonthControl(){
        if(this.service.outgoingMonthControl.attributes.hasOwnProperty('id') &&
            parseFloat(this.service.outgoingMonthControl.getFormId()) === parseFloat(this.incomingForm.getId())){}else{
            this.service.outgoingMonthControl.setOptions([]);
            this.service.outgoingMonthControl.setLabel('Month *');
            this.service.outgoingMonthControl.setCntrlNum(this.service.random());
            this.service.outgoingMonthControl.setControlId(5);
            this.service.outgoingMonthControl.setId(this.service.random());
            this.service.outgoingMonthControl.setFormId(this.incomingForm.getId());
            this.service.outgoingMonthControl.setControl({id:5,text:'',name:''});
            this.service.httpService( 'get', this.appmonthUrl, {}, {ignoreLoadingBar : 'true', notify: 'false'},
                (months: any) => {
                    months.map((month: any, l: any) => {
                        let ougoingMonth: any = new Parameters();
                        ougoingMonth.setAttributes({});
                        ougoingMonth.setId(l);
                        ougoingMonth.setMonthId(l);
                        ougoingMonth.setLabel(month);
                        ougoingMonth.setFormControlId(l);
                        ougoingMonth.setCntrlNum(this.service.random());
                        this.service.outgoingMonthControl.getOptions().push(ougoingMonth.getAttributes());
                    }); this.incomingForm.getControls().push(this.service.outgoingMonthControl.getAttributes());
                },  (error: any) => {
                });
        }

    }



    ngOnInit(): void {
        setTimeout(() => {
            this.incomingSettings.setAttributes(this.incomingForm.getSettings());
            this.incomingSettings.setDate(this.incomingSettings.attributes.hasOwnProperty('date') ? this.incomingSettings.getDate() : '');
            this.incomingSettings.setOutlet(this.incomingSettings.attributes.hasOwnProperty('outlet') ? this.incomingSettings.getOutlet() : '');
            this.incomingSettings.setCriterias(this.incomingSettings.attributes.hasOwnProperty('criterias') ? this.incomingSettings.getCriterias() : []);
            this.incomingSettings.setStock(this.incomingSettings.attributes.hasOwnProperty('stock') ? this.incomingSettings.getStock() : '');
            this.incomingSettings.setRateOptions(this.incomingSettings.attributes.hasOwnProperty('rate_options') ? this.incomingSettings.getRateOptions() : []);
            this.incomingSettings.setLogo(this.incomingSettings.attributes.hasOwnProperty('logo') ? this.incomingSettings.getLogo() : []);
            this.incomingSettings.setAttendeeName(this.incomingSettings.attributes.hasOwnProperty('attendee_name') ?
             this.incomingSettings.getAttendeeName() : '');
            this.incomingSettings.setIsReceivingInvitationCard(this.incomingSettings.attributes.hasOwnProperty(this.strings.getIsReceivingInvitationCard()) ?
                this.incomingSettings.getIsReceivingInvitationCard() : '');
            this.incomingSettings.setAttendeePic(this.incomingSettings.attributes.hasOwnProperty('attendee_pic') ?
                this.incomingSettings.getAttendeePic() : '');
            this.incomingSettings.setBanner(this.incomingSettings.attributes.hasOwnProperty('banner') ?
                this.incomingSettings.getBanner() : '');
            this.incomingSettings.setInvitationCardEmailed(this.incomingSettings.attributes.hasOwnProperty('invitationcard_emailed') ?
             this.incomingSettings.getInvitationCardEmailed() : '');
            this.incomingSettings.setInvitationCardSentThroughWhatsapp(this.incomingSettings.attributes.hasOwnProperty('invitationcard_sent_through_whatsapp') ?
                this.incomingSettings.getInvitationCardSentThroughWhatsapp() : '');
            this.incomingSettings.setContactPersonName(this.incomingSettings.attributes.hasOwnProperty('contact_person_name') ?
                 this.incomingSettings.getContactPersonName() : '');
            this.incomingSettings.setNotSure(this.incomingSettings.attributes.hasOwnProperty('not_sure') ?
                this.incomingSettings.getContactPersonName() : '');
            this.incomingSettings.setContactPersonPhone(this.incomingSettings.attributes.hasOwnProperty('contact_person_phone') ?
               this.incomingSettings.getContactPersonPhone() : '');
            this.incomingSettings.setContactPersonTitle(this.incomingSettings.attributes.hasOwnProperty('contact_person_title') ?
               this.incomingSettings.getContactPersonTitle() : '');
            this.incomingSettings.setContact(this.incomingSettings.attributes.hasOwnProperty('contact') ?
               this.incomingSettings.getContact() : '');
            this.incomingSettings.setResponseChannel(this.incomingSettings.attributes.hasOwnProperty('response_channel') ?
               this.incomingSettings.getResponseChannel() : '');
            this.incomingSettings.setIsInvited(this.incomingSettings.attributes.hasOwnProperty('is_invited') ?
               this.incomingSettings.getIsInvited() : '');
            this.incomingSettings.setInvitationCard(this.incomingSettings.attributes.hasOwnProperty('invitation_card') ?
               this.incomingSettings.getInvitationCard() : '');
            this.incomingSettings.setInvitationContents(this.incomingSettings.attributes.hasOwnProperty('invitation_contents') ?
               this.incomingSettings.getInvitationContents() : '');
            this.incomingSettings.setConfirmationContents(this.incomingSettings.attributes.hasOwnProperty('confirmation_contents') ?
               this.incomingSettings.getConfirmationContents() : '');
            this.incomingSettings.setIsInvitedByWhatsapp(this.incomingSettings.attributes.hasOwnProperty('is_invited_by_whatsapp') ?
                this.incomingSettings.getIsInvitedByWhatsapp() : '');
            this.incomingSettings.setAttendeePhone(this.incomingSettings.attributes.hasOwnProperty('attendee_phone') ?
               this.incomingSettings.getAttendeePhone() : '');
            this.incomingSettings.setIsAttending(this.incomingSettings.attributes.hasOwnProperty('is_attending') ?
               this.incomingSettings.getIsAttending() : '');
            this.incomingSettings.setJobPosition(this.incomingSettings.attributes.hasOwnProperty('job_position') ?
              this.incomingSettings.getJobPosition() : '');
            this.incomingSettings.setIsConfirm(this.incomingSettings.attributes.hasOwnProperty('is_confirm') ?
               this.incomingSettings.getIsConfirm() : '');
            this.incomingSettings.setCompanyName(this.incomingSettings.attributes.hasOwnProperty('company_name') ?
              this.incomingSettings.getCompanyName() : '');
            this.incomingSettings.setSalutation(this.incomingSettings.attributes.hasOwnProperty('salutation') ?
              this.incomingSettings.getSalutation() : '');
            this.incomingSettings.setLocation(this.incomingSettings.attributes.hasOwnProperty('location') ? this.incomingSettings.getLocation() : []);
            this.currentQuestionalProducts = this.incomingForm.getProducts();
            this.incomingRateOptionSelected.setAttributes(this.incomingSettings.getRateOptions().length === 0 ? {} : this.incomingSettings.getRateOptions()[0]);
            this.incomingCriteriaSelected.setAttributes(this.incomingSettings.getCriterias().length === 0 ? {} : this.incomingSettings.getCriterias()[0]);
            this.filteredCoreBusinessFieldOptions = this.coreBusinessFieldControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.questionControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredIsPayedQuestionsOptions = this.isPayedControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredOutletChannelQuestionsOptions = this.outletChannelControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredIsCardSentQuestionsOptions = this.isCardSentControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );


            this.filteredIsReceivingInvitationCardQuestionsOptions = this.isReceivingInvitationCardControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredIsEmailQuestionsOptions = this.isEmailControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsIsWhatsappOptions = this.isWhatsappControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.titleControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.contactControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredPaymentMethodQuestionsOptions = this.paymentMethodControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.responseChannelControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.bannerControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredDeliveredQuestionsOptions = this.deliveredControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );


            this.filteredQuestionsOptions = this.confirmationContentsControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );


            this.filteredInvitationCardQuestionsOptions = this.invitationCardControl.valueChanges.pipe(
                 startWith(''),
                 map(value => typeof value === 'string' ? value : value.label),
                 map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.isInvitedByWhatsappControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.contactPersonPhoneControl.valueChanges.pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.label),
                            map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.contactPersonTitleControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredAttendeePicQuestionsOptions = this.attendeePicControl.valueChanges.pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.label),
                            map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsAttendingOptions = this.isAttendingControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.coordinateControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredAttendeeNameQuestionsOptions = this.attendeeNameControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.invitationCardEmailedControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.invitationCardSentThroughWhatsappControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

             this.filteredQuestionsOptions = this.isInvitedControl.valueChanges.pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value.label),
                            map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
                        );

            this.filteredOrderStatusQuestionsOptions = this.orderStatusControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.uniqidControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.outletControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.addressControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredAttendeePhoneQuestionsOptions = this.attendeePhoneControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredAttendeeJobTitleQuestionsOptions = this.attendeeJobTitleControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredPackagesQuestionsOptions = this.packagesControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredAttendeeEmailQuestionsOptions = this.attendeeEmailControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredAttendeeCompanyQuestionsOptions = this.attendeeCompanyControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );


            this.filteredAttendeeSalutationQuestionsOptions = this.attendeeSalutationControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );


            this.filteredAttendeeJobPositionQuestionsOptions = this.attendeeJobPositionControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredTicketsQuestionsOptions = this.ticketsControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.isConfirmControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredInvoiceQuestionsOptions = this.invoiceControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.dateControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.stockItemControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            this.filteredQuestionsOptions = this.customReportControl.valueChanges.pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value.label),
                map(label => label ? this._filterQuestion(label) : this.incomingForm.getControls().slice())
            );

            setTimeout(() => {
                this.incomingflattenQuestions.setAttributes(this.service.getJSObjectByArrayAndLabel(this.incomingForm.getControls(), this.strings.getCntrlNum()));
                if(!this.service.isNull(this.incomingForm.getCoreBusinessField())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingForm.getCoreBusinessField())){
                        this.coreBusinessFieldControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingForm.getCoreBusinessField()));
                    }
                }

                if(!this.service.isNull(this.incomingSettings.getStock())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingSettings.getStock())){
                        this.stockItemControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getStock()));
                    }
                }

                if(!this.service.isNull(this.incomingSettings.getIsCardSent())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingSettings.getIsCardSent())){
                        this.isCardSentControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsCardSent()));
                    }
                }

                if(!this.service.isNull(this.incomingSettings.getIsPayed())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingSettings.getIsPayed())){
                        this.isPayedControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsPayed()));
                    }
                }

                if(!this.service.isNull(this.incomingSettings.getOutletChannel())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingSettings.getOutletChannel())){
                        this.outletChannelControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getOutletChannel()));
                    }
                }

                if(!this.service.isNull(this.incomingSettings.getIsReceivingInvitationCard())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingSettings.getIsReceivingInvitationCard())){
                        this.isReceivingInvitationCardControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsReceivingInvitationCard()));
                    }
                }

                if(!this.service.isNull(this.incomingSettings.getIsEmail())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(
                        this.incomingSettings.getIsEmail())){
                        this.isEmailControl.setValue(this.incomingflattenQuestions.getUnknown(
                            this.incomingSettings.getIsEmail()));
                    }
                }

                if(!this.service.isNull(this.incomingSettings.getIsWhatsapp())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingSettings.getIsWhatsapp())){
                        this.isWhatsappControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsWhatsapp()));
                    }
                }

                if(!this.service.isNull(this.incomingForm.getTitle())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingForm.getTitle())){
                        this.titleControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingForm.getTitle()));
                    }
                }


                if(!this.service.isNull(this.incomingForm.getGpsReference())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingForm.getGpsReference())){
                        this.coordinateControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingForm.getGpsReference()));
                    }
                }

                if(!this.service.isNull(this.incomingForm.getUniqid())){
                    if(this.incomingflattenQuestions.getAttributes().hasOwnProperty(this.incomingForm.getUniqid())){
                        this.uniqidControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingForm.getUniqid()));
                    }
                }


                if(!this.service.isNull(this.incomingSettings.getLocation()))
                    this.addressControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getLocation()));

                if(!this.service.isNull(this.incomingSettings.getAttendeeJobPosition()))
                   this.attendeeJobPositionControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeeJobPosition()));

                if(!this.service.isNull(this.incomingSettings.getTickets()))
                    this.ticketsControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getTickets()));

                if(!this.service.isNull(this.incomingSettings.getAttendeeJobTitle()))
                    this.attendeeJobTitleControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeeJobTitle()));

                if(!this.service.isNull(this.incomingSettings.getPackages()))
                    this.packagesControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getPackages()));

                if(!this.service.isNull(this.incomingSettings.getAttendeeName()))
                    this.attendeeNameControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeeName()));

                if(!this.service.isNull(this.incomingSettings.getInvitationCardEmailed()))
                    this.invitationCardEmailedControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getInvitationCardEmailed()));

                if(!this.service.isNull(this.incomingSettings.getInvitationCardSentThroughWhatsapp()))
                    this.invitationCardSentThroughWhatsappControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getInvitationCardSentThroughWhatsapp()));


                if(!this.service.isNull(this.incomingSettings.getInvoice()))
                    this.invoiceControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getInvoice()));

                if(!this.service.isNull(this.incomingSettings.getIsInvitedByWhatsapp()))
                   this.isInvitedByWhatsappControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsInvitedByWhatsapp()));

                if(!this.service.isNull(this.incomingSettings.getAttendeeEmail()))
                    this.attendeeEmailControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeeEmail()));

                if(!this.service.isNull(this.incomingSettings.getDelivered()))
                    this.deliveredControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getDelivered()));

                if(!this.service.isNull(this.incomingSettings.getConfirmationContents()))
                    this.confirmationContentsControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getConfirmationContents()));

                if(!this.service.isNull(this.incomingSettings.getInvitationCard()))
                   this.invitationCardControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getInvitationCard()));

                if(!this.service.isNull(this.incomingSettings.getIsInvited()))
                    this.isInvitedControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsInvited()));

                if(!this.service.isNull(this.incomingSettings.getOrderStatus()))
                    this.orderStatusControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getOrderStatus()));

                if(!this.service.isNull(this.incomingSettings.getContact()))
                    this.contactControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getContact()));

                if(!this.service.isNull(this.incomingSettings.getPaymentMethod()))
                    this.paymentMethodControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getPaymentMethod()));

                if(!this.service.isNull(this.incomingSettings.getResponseChannel()))
                    this.responseChannelControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getResponseChannel()));

                if(!this.service.isNull(this.incomingSettings.getBanner()))
                    this.bannerControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getBanner()));

                if(!this.service.isNull(this.incomingSettings.getContactPersonPhone()))
                   this.contactPersonPhoneControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getContactPersonPhone()));

                if(!this.service.isNull(this.incomingSettings.getContactPersonTitle()))
                   this.contactPersonTitleControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getContactPersonTitle()));

                if(!this.service.isNull(this.incomingSettings.getAttendeePic()))
                   this.attendeePicControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeePic()));

                if(!this.service.isNull(this.incomingSettings.getIsConfirm()))
                    this.isConfirmControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsConfirm()));

                if(!this.service.isNull(this.incomingSettings.getAttendeePhone()))
                   this.attendeePhoneControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeePhone()));

                if(!this.service.isNull(this.incomingSettings.getIsAttending()))
                   this.isAttendingControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getIsAttending()));


                if(!this.service.isNull(this.incomingSettings.getAttendeeCompany()))
                   this.attendeeCompanyControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeeCompany()));

                if(!this.service.isNull(this.incomingSettings.getAttendeeSalutation()))
                   this.attendeeSalutationControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getAttendeeSalutation()));


                if(!this.service.isNull(this.incomingSettings.getOutlet())){
                    this.outletControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getOutlet()));
                }

                if(!this.service.isNull(this.incomingSettings.getDate())){
                    this.dateControl.setValue(this.incomingflattenQuestions.getUnknown(this.incomingSettings.getDate()));
                }

                this.subscription =  this.broadcastChannel.getNavChangeEmitter().pipe()
                    .subscribe((item: any) => {
                        if(item instanceof Object){
                            if(item.hasOwnProperty('prompt') === true) {
                                this.incomingCriteriaSelected.getQuestions().push(this.getCriteriaQuestion(item.prompt));
                            }else if(item.hasOwnProperty('_criteria')) {
                                this.update();
                            }else if(item.hasOwnProperty('criteriaSelected')) {
                                this.incomingCriteriaSelected.setAttributes(this.incomingSettings.getCriterias().filter((criteria: any) => {return criteria.cntrl_num.toString().includes(item.criteriaSelected.cntrl_num.toString());})[0]);
                            }

                        }
                    });

            });


        });

        this.service.httpService( 'get', '../assets/json/reports.json', {}, {},(response: any) => {
            this.charts = response;
            if(this.formService.customReportSelectedId !== null){
                this.incomingCustomReportSelected.setAttributes(
                    this.service.getJSONObjectByJSONArrayAndColumnAndValue(this.incomingForm.getCustomReports().data, 'id', this.formService.customReportSelectedId));
            }else if(this.incomingForm.getCustomReports().data.length !== 0)
                this.incomingCustomReportSelected.setAttributes(this.incomingForm.getCustomReports().data[0]);
        },  (error: any) => {});
        this.service.httpService( 'get', '../assets/json/operators.json', {}, {},(response: any) => {this.operators = response;},  (error: any) => {});
    }

    setTeamsControl(){
        if(this.service.outgoingTeamsControl.attributes.hasOwnProperty('id') &&
            parseFloat(this.service.outgoingTeamsControl.getFormId()) === parseFloat(this.incomingForm.getId())){}else{
            this.service.outgoingTeamsControl.setOptions([]);
            this.service.outgoingTeamsControl.setLabel('Teams *');
            this.service.outgoingTeamsControl.setCntrlNum(this.service.random());
            this.service.outgoingTeamsControl.setControlId(5);
            this.service.outgoingTeamsControl.setId(this.service.random());
            this.service.outgoingTeamsControl.setFormId(this.incomingForm.getId());
            this.service.outgoingTeamsControl.setControl({id:5,text:'',name:''});
            this.service.app.data.teams.data.filter((team: any) => {
                this.incomingTeam.setAttributes(team);
                return parseFloat(this.incomingTeam.getCampaignId()) === parseFloat(this.incomingForm.getCampaignId());
            }).map((team: any) => {
                this.incomingTeam.setAttributes(Object.assign({},team));
                this.outgoingTeam.setAttributes({});
                this.outgoingTeam.setId(this.incomingTeam.getId());
                this.outgoingTeam.setTeamId(this.incomingTeam.getId());
                this.outgoingTeam.setLabel(this.incomingTeam.getName());
                this.outgoingTeam.setCntrlNum(this.incomingTeam.getCntrlNum());
                this.outgoingTeam.setFormControlId(this.service.outgoingTeamsControl.getId());
                this.service.outgoingTeamsControl.getOptions().push(this.outgoingTeam.getAttributes());
            });
            this.incomingForm.getControls().push(this.service.outgoingTeamsControl.getAttributes());
        }
    }


    setUsersControl(){
            if(this.service.outgoingUsersControl.attributes.hasOwnProperty('id') &&
                parseFloat(this.service.outgoingUsersControl.getFormId()) === parseFloat(this.incomingForm.getId())){
            }else{
                this.service.outgoingUsersControl.setOptions([]);
                this.service.outgoingUsersControl.setLabel('Ambassadors *');
                this.service.outgoingUsersControl.setCntrlNum(this.service.random());
                this.service.outgoingUsersControl.setControlId(5);
                this.service.outgoingUsersControl.setId(this.service.random());
                this.service.outgoingUsersControl.setFormId(this.incomingForm.getId());
                this.service.outgoingUsersControl.setControl({id:5,text:'',name:''});
                this.incomingForm.getInvitees().data.map((user: any) => {
                    this.incomingUser.setAttributes(Object.assign({},user));
                    this.outgoingUser.setAttributes({});
                    this.outgoingUser.setId(this.incomingUser.getId());
                    this.outgoingUser.setUserId(this.incomingUser.getId());
                    this.outgoingUser.setLabel(this.incomingUser.getName());
                    this.outgoingUser.setCntrlNum(this.incomingUser.getPlaceId());
                    this.outgoingUser.setFormControlId(this.service.outgoingUsersControl.getId());
                    this.service.outgoingUsersControl.getOptions().push(this.outgoingUser.getAttributes());
                });
                this.incomingForm.getControls().push(this.service.outgoingUsersControl.getAttributes());
            }
    }


    setDistrctsControl(){
            if(this.service.outgoingDistrictsControl.attributes.hasOwnProperty('id') &&
                parseFloat(this.service.outgoingDistrictsControl.getFormId()) === parseFloat(this.incomingForm.getId())){
            }else{
                this.service.outgoingDistrictsControl.setOptions([]);
                this.service.outgoingDistrictsControl.setLabel('Districts *');
                this.service.outgoingDistrictsControl.setCntrlNum(this.service.random());
                this.service.outgoingDistrictsControl.setControlId(5);
                this.service.outgoingDistrictsControl.setId(this.service.random());
                this.service.outgoingDistrictsControl.setFormId(this.incomingForm.getId());
                this.service.outgoingDistrictsControl.setControl({id:5,text:'',name:''});
                this.service.app.data.regions.data.map((region: any) => {
                    this.incomingRegion.setAttributes(Object.assign({},region));
                    this.incomingRegion.getDistricts().map((district: any)=>{
                     this.incomingDistrict.setAttributes(district);
                     this.outgoingDistrict.setAttributes({});
                     this.outgoingDistrict.setId(this.incomingDistrict.getId());
                     this.outgoingDistrict.setDistrictId(this.incomingDistrict.getId());
                     this.outgoingDistrict.setPlaceId(this.incomingDistrict.getPlaceId());
                     this.outgoingDistrict.setLabel(this.incomingDistrict.getName());
                     this.outgoingDistrict.setCntrlNum(this.incomingDistrict.getPlaceId());
                     this.outgoingDistrict.setFormControlId(this.service.outgoingDistrictsControl.getId());
                     this.service.outgoingDistrictsControl.getOptions().push(this.outgoingDistrict.getAttributes());
                    });
                });
                this.incomingForm.getControls().push(this.service.outgoingDistrictsControl.getAttributes());
            }

    }
    
    setOutletControl(){
        if(this.service.outgoingOutletsControl.attributes.hasOwnProperty('id') &&
            parseFloat(this.service.outgoingOutletsControl.getFormId()) === parseFloat(this.incomingForm.getId())){
        }else{
            this.service.outgoingOutletsControl.setOptions([]);
            this.service.outgoingOutletsControl.setLabel('Outlets*');
            this.service.outgoingOutletsControl.setCntrlNum(this.service.random());
            this.service.outgoingOutletsControl.setControlId(5);
            this.service.outgoingOutletsControl.setId(this.service.random());
            this.service.outgoingOutletsControl.setFormId(this.incomingForm.getId());
            this.service.outgoingOutletsControl.setControl({id:5,text:'',name:''});
            this.service.app.data.outlets.types.map((outlet: any) => {
                this.incomingOutlet.setAttributes(Object.assign({},outlet));
                this.outgoingOutlet.setAttributes({});
                this.outgoingOutlet.setId(this.incomingOutlet.getId());
                this.outgoingOutlet.setOutletTypeId(this.incomingOutlet.getId());
                this.outgoingOutlet.setLabel(this.incomingOutlet.getName());
                this.outgoingOutlet.setCntrlNum(this.incomingOutlet.getCntrlNum());
                this.outgoingOutlet.setFormControlId(this.service.outgoingOutletsControl.getId());
                this.service.outgoingOutletsControl.getOptions().push(this.outgoingOutlet.getAttributes());
            });
            this.incomingForm.getControls().push(this.service.outgoingOutletsControl.getAttributes());
        }
    }



    hasGroupBy(){
    }

    removePoweredBy(){
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setSample('form.logos');
        this.outgoingRequest.setEntity1(this.incomingForm.getId());
        this.outgoingRequest.setEntity2(this.incomingPoweredBySelected.getId());
        this.outgoingRequest.setId(this.incomingPoweredBySelected.getFormImageId());
        this.service.httpService('post', this.service.app.routes.entities.delete, this.outgoingRequest.getAttributes(), {}, (response: any) => {
            this.incomingForm.attributes.settings.logo.splice(this.service.findIndex(this.incomingForm.attributes.settings.logo, 'form_image_id', this.outgoingRequest.getId()), 1);
            this.notifier.notify('success', 'Successfull deleted');
        }, (error: any) => {
        });

    }

    updateCriteria(){
        let text: any = prompt('', this.incomingCriteriaSelected.getTitle());
        if(!this.service.isNull(text)) {
            this.incomingCriteriaSelected.setTitle(text);
            this.update();
        }
    }

    openSettingsDialog(criteria: any){
//       this.dialog.open(SettingsCriteriasDialogComponent , { criteria: criteria, width  : '55%', height : '100%'});
        this.dialog.open(SettingsCriteriasDialogComponent, {data  : {criteria: criteria}, width : '55%', height: '100%', });
    }

    setCriteriaHasGroupBy(question: any){
        this.incomingQuestion.setAttributes(question);
        this.incomingQuestion.setGroupBy(this.incomingQuestion.hasAttribute('group_by') ? this.incomingQuestion.getGroupBy() : 0);
        if(parseFloat(this.incomingQuestion.getGroupBy()) === 1) {
            this.incomingCriteriaSelected.setGroupBy(1);
        }else this.incomingCriteriaSelected.setGroupBy(0);
    }

    addRateOption(){
        let text: any = prompt('Rate option', '');
        if(!this.service.isNull(text)) {
            this.incomingRateOption.setAttributes({});
            this.incomingRateOption.setCntrlNum(this.service.random());
            this.incomingRateOption.setTitle(text);
            this.incomingRateOption.setIndex(this.incomingSettings.getRateOptions().length);
            this.incomingSettings.getRateOptions().push(this.incomingRateOption.getAttributes());
            this.incomingRateOptionSelected.setAttributes(this.incomingRateOption.getAttributes());
            this.update();
        }
    }

    updateRateOption(){
        let text: any = prompt('', this.incomingRateOptionSelected.getTitle());
        if(!this.service.isNull(text)) {
            this.incomingRateOptionSelected.setTitle(text);
            this.update();
        }
    }

    getCriteriaQuestion(question: any){
        this.incomingQuestion.setAttributes(Object.assign({}, question));
        this.outgoingQuestion.setAttributes({});
        this.outgoingQuestion.setId(this.incomingQuestion.getId());
        this.outgoingQuestion.setLabel(this.incomingQuestion.getLabel());
        this.outgoingQuestion.setControlId(this.incomingQuestion.getControlId());
        this.outgoingQuestion.setGroupBy(0);
        this.outgoingQuestion.setValue('');
        this.outgoingQuestion.setPrimaryGroupBy(0);
        this.outgoingQuestion.setSecondaryGroupBy(0);
        this.outgoingQuestion.setLabel(this.incomingQuestion.getLabel());
        this.outgoingQuestion.setOperator('=');
        this.outgoingQuestion.setCntrlNum(this.incomingQuestion.getCntrlNum());
        this.outgoingQuestion.setOptions(this.incomingQuestion.getOptions().map((option: any)=>{
            this.incomingOption.setAttributes(Object.assign({}, option));
            this.outgoingOption.setAttributes({});
            this.outgoingOption.setId(this.incomingOption.getId());
            this.outgoingOption.setFormControlId(this.incomingOption.getFormControlId());
            this.outgoingOption.setQuestionId(this.incomingOption.getFormControlId());
            this.outgoingOption.setLabel(this.incomingOption.getLabel());
            this.outgoingOption.setChecked(0);
            this.outgoingOption.setCntrlNum(this.incomingOption.getCntrlNum());
            if(this.incomingOption.attributes.hasOwnProperty('region_id')){
                this.outgoingOption.setRegionId(this.incomingOption.getRegionId());
                this.outgoingOption.setPlaceId(this.incomingOption.getPlaceId());
            }else if(this.incomingOption.attributes.hasOwnProperty('district_id')){
                this.outgoingOption.setDistrictId(this.incomingOption.getDistrictId());
                this.outgoingOption.setPlaceId(this.incomingOption.getPlaceId());
            }else if(this.incomingOption.attributes.hasOwnProperty('user_id')){
                this.outgoingOption.setUserId(this.incomingOption.getUserId());
            }else if(this.incomingOption.attributes.hasOwnProperty('team_id')){
                this.outgoingOption.setTeamId(this.incomingOption.getTeamId());
            } else if(this.incomingOption.attributes.hasOwnProperty('outlet_type_id')){
                this.outgoingOption.setOutletTypeId(this.incomingOption.getOutletTypeId());
            }else if(this.incomingOption.attributes.hasOwnProperty(this.strings.getDayId())){
                this.outgoingOption.setDayId(this.incomingOption.getDayId());
            }else if(this.incomingOption.attributes.hasOwnProperty(this.strings.getMonthId())){
                this.outgoingOption.setMonthId(this.incomingOption.getMonthId());
            }else if(this.incomingOption.attributes.hasOwnProperty(this.strings.getWeekId())){
                this.outgoingOption.setWeekId(this.incomingOption.getWeekId());
            }
            return this.outgoingOption.getAttributes();
        })); return this.outgoingQuestion.getAttributes();
    }

    hasCompleteCriteriaQuestions(event: any,question: any){
        setTimeout(() => {
            this.result = 0;
            this.incomingQuestion.setAttributes(question);
            this.incomingQuestion.setPrimaryGroupBy(0);
            this.incomingCriteriaSelected.getQuestions().map((item: any) => {
                this.outgoingQuestion.setAttributes(Object.assign({}, item));
                if(parseFloat(this.outgoingQuestion.getGroupBy()) === 1){
                    this.result+= parseFloat('1');
                    this.incomingResult.setAttributes(this.outgoingQuestion.getAttributes());
                }
            });
            if(this.result === parseFloat('1')){
                this.incomingCriteriaSelected.setLevel(2);
                this.incomingQuestion.setPrimaryGroupBy(1);
            }
            if(this.result === parseFloat('2')) {
                this.incomingCriteriaSelected.setLevel(3);
                this.incomingQuestion.setSecondaryGroupBy(1);
            }
            if(this.result === parseFloat('1') || this.result === parseFloat('2')){
                this.checkAllCriteriaQuestionOptions(this.incomingQuestion.getAttributes());
            }else{
                this.incomingQuestion.setGroupBy(0);
                this.incomingCriteriaSelected.setLevel(1);
                event.checked = 0;
            }
            this.update();
        });
    }

    checkCriteriaQuestionOption(event:any, question:any,option: any,element: any){
        setTimeout(() => {
            this.incomingQuestion.setAttributes(question);
            this.incomingOption.setAttributes(option);
            if(parseFloat(this.incomingQuestion.getGroupBy()) === 1){
                if(!this.hasCriteriaQuestionOptionChecked(this.incomingQuestion.getAttributes())){
                    element.checked = true;
                    option.checked = 1;
                }
            }
        });

    }

    updateSettings(){
        const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: '',action:''}, width : 'auto', });
        dialogRef.afterClosed().subscribe((item: any) => {
            if (item instanceof Object){
                if (item.hasOwnProperty('response')){
                    if (item.response.toString().includes('successfull')){
                        setTimeout(() => {
                            if(parseFloat(this.formService.getSelected()[this.strings.getId()]) === parseFloat(this.incomingForm.getId())){
                                this.outgoingRequest.setAttributes({});
                                this.outgoingRequest.setId(this.incomingForm.getId());
                                this.outgoingRequest.setSettings(JSON.stringify(this.incomingForm.getSettings()));
                                this.service.httpService( 'POST',
                                    this.service.app.routes.forms.settings.update, this.outgoingRequest.getAttributes(),
                                    {ignoreLoadingBar: 'true',notify: 'false'},(response: any) => {
                                    this.dialogRef.close();
                                    },  (error: any) => {});
                            }
                        });
                    }
                }
            }
        });
    }

    hasCriteriaQuestionOptionChecked(question: any){
        this.result = false;
        this.incomingQuestion.setAttributes(question);
        this.incomingQuestion.getOptions().map((option: any) => {
            this.outgoingOption.setAttributes(Object.assign({},option));
            if(this.outgoingOption.hasAttribute('checked')){
                if(parseFloat(this.outgoingOption.getChecked()) === 1){
                    this.result = true;
                }
            }
        });
        return this.result;
    }

    checkAllCriteriaQuestionOptions(question: any){
        this.incomingQuestion.setAttributes(question);
        if(parseFloat(this.incomingQuestion.getGroupBy()) === 1){
            if(!this.hasCriteriaQuestionOptionChecked(question)){
                this.incomingQuestion.getOptions().map((option: any) => {
                    this.incomingOption.setAttributes(option);
                    this.incomingOption.setChecked(1);
                });
            }
        }
    }

    setCriteriaQuestionOperator(operator:any){
        this.incomingCriteriaQuestionSelected.setOperator(operator);
        this.update();
    }

    deleteCriteria(){
        this.incomingSettings.getCriterias().splice(this.service.findIndex(this.incomingSettings.getCriterias(), this.strings.getCntrlNum(), this.incomingCriteriaSelected.getCntrlNum()), 1);
        this.incomingCriteriaSelected.setAttributes(this.incomingSettings.getCriterias().length === 0 ? {} : this.incomingSettings.getCriterias()[0]);
        this.update();
    }

    removeRateOption(){
        this.incomingSettings.getRateOptions().splice(this.service.findIndex(this.incomingSettings.getRateOptions(), this.strings.getCntrlNum(), this.incomingRateOptionSelected.getCntrlNum()), 1);
        this.incomingRateOptionSelected.setAttributes(this.incomingSettings.getRateOptions().length === 0 ? {} : this.incomingSettings.getRateOptions()[0]);
        this.update();
    }

    selectQuestion(){
        this.addControls();
        this.dialog.open(PromptComponent, {
            data  : {flag: 'questions',formId: this.incomingForm.getId()},
            width  : 'auto',
            height : 'auto',
        });
    }

    onCustomReportSelected(report: any){
        this.incomingCustomReportSelected.setAttributes(report);
        this.formService.customReportSelectedId = this.incomingCustomReportSelected.getId();
    }

    removeCriteriaQuestion(question: any,position){
        this.incomingQuestion.setAttributes(Object.assign({},question));
        if(this.incomingQuestion.attributes.hasOwnProperty(this.strings.getCntrlNum())){
            this.position2 = this.incomingCriteriaSelected.getQuestions().findIndex((question: any) => {
                return question.cntrl_num.toString().includes(this.incomingQuestion.getCntrlNum());
            });
        }else this.position2 = position;
        this.incomingCriteriaSelected.getQuestions().splice(this.position2, 1);
        if(parseFloat(this.incomingQuestion.getGroupBy())=== 1)
            this.incomingCriteriaSelected.setGroupBy(0);
        this.update();
    }

    addCustomReport(){
        let text: any = prompt('Custom Report Title', '');
        if(!this.service.isNull(text)) {
            this.incomingCustomReport.setAttributes({});
            this.incomingCustomReport.setTitle(text);
            this.incomingCustomReport.setQuestions([]);
            this.incomingCustomReport.setDescription('');
            this.incomingCustomReport.setGroupBy('');
            this.incomingCustomReport.setFormId(this.incomingForm.getId());
            this.incomingCustomReport.setChart(1);
            this.incomingCustomReport.setCntrlNum(this.service.random());
            this.service.httpService( 'post', '/forms/xx/reports/addrequest', this.incomingCustomReport.getAttributes(), {},
                (response: any) => {
                    this.incomingCustomReport.setAttributes(response);
                    this.formService.customReportSelectedId = this.incomingCustomReport.getId();
                    this.incomingForm.getCustomReports().data.push(this.incomingCustomReport.getAttributes());
                    this.incomingCustomReportSelected.setAttributes(this.incomingForm.getCustomReports().data[this.incomingForm.getCustomReports().data.length - 1]);
                },  (error: any) => {
                });
        }
    }

    updateCustomReport(customReport: any){
        this.incomingCustomReport.setAttributes(customReport);
        this.incomingCustomReport.setQuestions(JSON.stringify(this.incomingCustomReport.getQuestions()));
        this.service.httpService( 'post', '/forms/xx/reports/updaterequest', this.incomingCustomReport.getAttributes(), {ignoreLoadingBar: 'true', notify: false},
            (response: any) => {
                this.incomingCustomReportSelected.attributes = Object.assign(this.incomingCustomReportQuestionSelected.attributes, response);
            },  (error: any) => {
            });
    }

    getChartById(id: any){
        return this.charts.filter((chart: any) => {return chart.id === id;})[0];
    }

    getChecked(question: any){
        return !this.service.isNull(this.incomingCustomReportSelected.getGroupBy()) && question.cntrl_num.toString().includes(this.incomingCustomReportSelected.getGroup());
    }

    handleCustomReportQuestionSelected(question: any, report: any){
        this.incomingCustomReport.setAttributes(report);
        this.incomingQuestion.setAttributes(question);
        this.incomingCustomReport.setGroupBy(this.incomingQuestion.getCntrlNum());
    }

    upShiftCriteria(criteria: any, position: any){
        this.result = this.service.orderBy(this.incomingSettings.getCriterias(),'asc','index');
        this.incomingCriteria.setAttributes(criteria);
        this.ougoingCriteria.setAttributes(this.result[position - 1]);
        this.ougoingCriteria.setIndex(position);
        this.incomingCriteria.setIndex(position - 1);
        this.update();
    }

    downShiftCriteria(criteria: any, position: any){
        this.result = this.service.orderBy(this.incomingSettings.getCriterias(),'asc','index');
        this.incomingCriteria.setAttributes(criteria);
        this.ougoingCriteria.setAttributes(this.result[position + 1]);
        this.ougoingCriteria.setIndex(position);
        this.incomingCriteria.setIndex(position + 1);
        this.update();
    }

    addControls(){
     if(!(this.service.outgoingRegionsControl.attributes.hasOwnProperty('id') &&
               parseFloat(this.service.outgoingRegionsControl.getFormId()) === parseFloat(this.incomingForm.getId()))){
        this.setRegionsControl();
        this.setDistrctsControl();
        this.setTeamsControl();
        this.setUsersControl();
        this.setOutletControl();
        this.setCustomControl();
        this.setDaysControl();
        this.setWeeksControl();
        this.setMonthControl();
     }
    }

    setCustomControl(){
        this.incomingSettings.getCriterias().map((criteria: any) => {
            this.incomingCriteria.setAttributes(criteria);
            this.incomingCriteria.getQuestions().map((question: any)=>{
                this.incomingCriteriaQuestion.setAttributes(question);
                if(parseFloat(this.incomingCriteriaQuestion.getControlId()) ===-18){
                    this.position1 = this.service.findIndex(this.incomingForm.getControls(),this.strings.getCntrlNum(),this.incomingCriteriaQuestion.getCntrlNum());
                    if(this.position1==-1){
                        this.incomingForm.getControls().push(this.incomingCriteriaQuestion.getAttributes());
                        this.customQuestions.push(this.incomingCriteriaQuestion.getCntrlNum());
                    }
                }
            });
        });
    }

    addCriteria(){
        this.addControls();
        let text: any = prompt('Set criteria title', '');
        if(!this.service.isNull(text)) {
            this.incomingCriteria.setAttributes({});
            this.incomingCriteria.setTitle(text);
            this.incomingCriteria.setQuestions([]);
            this.incomingCriteria.setDescription('');
            this.incomingCriteria.setFormId(this.incomingForm.getId());
            this.incomingCriteria.setChart(1);
            this.incomingCriteria.setGroupBy(0);
            this.incomingCriteria.setLevel(1);
            this.incomingCriteria.setIndex(this.incomingSettings.getCriterias().length + 1);
            this.incomingCriteria.setCntrlNum(this.service.random());
            this.incomingSettings.getCriterias().unshift(this.incomingCriteria.getAttributes());
            this.incomingCriteriaSelected.setAttributes(this.incomingCriteria.getAttributes());
            this.update();
        }
    }

    onOptionSelected(){}
    //
    afterContentEditable(text: any, customReport: any){
        customReport = Object.assign(customReport, {title: text});
        this.updateCustomReport(customReport);
    }

    private _filterProduct(value: any): any {
        const filterValue = value.toString().toLowerCase();
        return this.allProductOptions.filter(question => question.label.toString().toLowerCase().includes(filterValue));
    }

    remove(questional: any): void {
        try {
            this.incomingQuestion.setAttributes(questional);
            this.currentQuestionalProducts.splice(
                this.service.findIndex(this.currentQuestionalProducts, this.strings.getCntrlNum(),
                    this.incomingQuestion.getCntrlNum()), 1);
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSample('form.products');
            this.outgoingRequest.setEntity1(this.incomingForm.getId());
            this.outgoingRequest.setEntity2(this.incomingQuestion.getId());
            this.service.httpService('post', '/entities/sampling',
                this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false},(response: any) => {}, (error: any) => {
                });
        }catch (e) {}
    }

    getImg(event: any){
        this.incomingSettings.setAttributes(this.incomingForm.getSettings());
        this.incomingSettings.setLogo(this.incomingSettings.attributes.hasOwnProperty('logo') ?
            this.incomingSettings.getLogo() : []);
        const files: FileList = event.target.files;
        const reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            this.incomingAvatar.setAttributes({});
            this.incomingAvatar.setSrc(reader.result);
            this.incomingAvatar.setFlag(0);
            this.incomingAvatar.setCaption('');
            this.incomingAvatar.setOrderBy('');
            this.incomingAvatar.setStatus(1);
            this.incomingAvatar.setUrl('');
            this.incomingAvatar.setPath('');
            this.incomingSettings.getLogo().push(this.incomingAvatar.getAttributes());
            if(this.incomingForm.attributes.hasOwnProperty('id')){
                this.incomingAvatar.setAvatar(this.service.dataURItoBlob(reader.result));
                setTimeout((params: any) => {
                    this.service.httpService('post', this.service.app.routes.images.add,
                        this.incomingAvatar.getAttributes(), {},(image: any) => {
                            this.incomingAvatar.attributes = Object.assign(this.incomingAvatar.attributes, image);
                            if(this.flag === 0){
                            this.outgoingRequest.setAttributes({});
                            this.outgoingRequest.setEntity1(params.entity1);
                            this.outgoingRequest.setEntity2(this.incomingAvatar.getId());
                            this.outgoingRequest.setDescription('');
                            this.outgoingRequest.setSample('form.logos');
                            this.addEntity(this.outgoingRequest);
                            }else if(this.flag === 1){
                                this.incomingCriteriaSelected.setSrc(this.incomingAvatar.getPath());
                                this.update();
                                this.flag = 0;
                            }
                        }, (error: any) => {
                        });
                },0, {entity1 : this.incomingForm.getId()});
            }
        };
    }

    openPromptQuestionlabel(question: any){
        this.incomingQuestion.setAttributes(question);
        let resultText: any = prompt('', this.incomingQuestion.getUnknown('label'));
        if(!this.service.isNull(resultText)) {
            this.incomingQuestion.setReference1(resultText);
            // this.incomingSettings.getCriterias().map((criteria: any) => {
            //     this.incomingCriteria.setAttributes(criteria);
            //     this.incomingCriteria.getQuestions().map((criteriaQuestion) => {
            //         this.incomingCriteriaQuestion.setAttributes(criteriaQuestion);
            //         if(this.incomingQuestion.getCntrlNum().includes(this.incomingCriteriaQuestion.getCntrlNum())) {
            //             if (parseFloat(this.incomingCriteriaQuestion.getControlId()) === -18) {
            //                 this.incomingCriteriaQuestion.setUnknown('label', resultText);
            //             } else this.incomingCriteriaQuestion.setReference1(resultText);
            //         }
            //     });
            // });
        }
        this.update();
    }

    addCriteriaQuestion(){
        let resultText: any = prompt('', '');
        if(!this.service.isNull(resultText)) {
            this.incomingControl.setAttributes({});
            this.incomingControl.setId(-18);
            this.incomingControl.setText('');
            this.incomingControl.setName('');
            this.incomingCriteriaQuestion.setAttributes({});
            this.incomingCriteriaQuestion.setOptions([]);
            this.incomingCriteriaQuestion.setLabel(resultText);
            this.incomingCriteriaQuestion.setCntrlNum(this.service.random());
            this.incomingCriteriaQuestion.setControlId(-18);
            this.incomingCriteriaQuestion.setOptions([]);
            this.incomingCriteriaQuestion.setId(new Date().getTime());
            this.incomingCriteriaQuestion.setFormId(this.incomingForm.getId());
            this.incomingCriteriaQuestion.setControl(this.incomingControl.getAttributes());
            this.incomingCriteriaSelected.getQuestions().push(this.incomingCriteriaQuestion.getAttributes());
            this.setCustomControl();
            this.update();
        }
    }

    openPromptQuestionOptionlabel(question: any,option: any){
        this.incomingQuestion.setAttributes(question);
        this.incomingOption.setAttributes(option);
        let resultText: any = prompt('', this.incomingOption.getUnknown('label'));
        if(!this.service.isNull(resultText)) {
            this.incomingSettings.getCriterias().map((criteria: any) => {
                this.incomingCriteria.setAttributes(criteria);
                this.incomingCriteria.getQuestions().map((criteriaQuestion: any) => {
                    this.incomingCriteriaQuestion.setAttributes(criteriaQuestion);
                    if(parseFloat(this.incomingCriteriaQuestion.getControlId()) === -18){
                        this.incomingCriteriaQuestion.getOptions().map((criteriaQuestionOption: any) => {
                            this.incomingCriteriaQuestionOption.setAttributes(criteriaQuestionOption);
                            if(this.incomingCriteriaQuestionOption.getCntrlNum().includes(this.incomingOption.getCntrlNum())){
                                this.incomingCriteriaQuestionOption.setUnknown('label',resultText);
                            }
                        });
                    }
                });
            });
        }
        this.update();
    }

    addCriteriaQuestionOption(question: any){
        let resultText: any = prompt('', '');
        if(!this.service.isNull(resultText)) {
            this.incomingQuestion.setAttributes(question);
            this.incomingCriteriaQuestionOption.setAttributes({});
            this.incomingCriteriaQuestionOption.setId(new Date().getTime());
            this.incomingCriteriaQuestionOption.setChecked(0);
            this.incomingCriteriaQuestionOption.setLabel(resultText);
            this.incomingCriteriaQuestionOption.setCntrlNum(this.service.random());
            this.incomingCriteriaQuestionOption.setQuestionId(this.incomingQuestion.getId());
            this.incomingSettings.getCriterias().map((criteria: any)=>{
                this.incomingCriteria.setAttributes(criteria);
                this.incomingCriteria.getQuestions().map((criteriaQuestion: any) => {
                    this.incomingCriteriaQuestion.setAttributes(criteriaQuestion);
                    if(this.incomingCriteriaQuestion.getCntrlNum().includes(this.incomingQuestion.getCntrlNum())){
                        this.incomingCriteriaQuestion.getOptions().push(Object.assign({}, this.incomingCriteriaQuestionOption.getAttributes()));
                    }
                });
            });
            this.update();
        }
    }

    addEntity(outgoingRequest: any){
        this.entityService.add(outgoingRequest.getAttributes(),(entity) => {});
    }

    onCustomReportQuestionSelected(event: MatAutocompleteSelectedEvent, customReport: any): void{
        this.incomingQuestion.setAttributes(event.option.value);
        this.incomingCustomReportSelected.setAttributes(customReport);
        if(this.service.findIndex(this.incomingCustomReportSelected.getQuestions(), this.strings.getCntrlNum(), this.incomingQuestion.getCntrlNum()) === -1){
            this.incomingQuestion.setStatus(1);
            this.incomingCustomReportSelected.getQuestions().push(this.incomingQuestion.getAttributes());
            this.updateCustomReport(this.incomingCustomReportSelected.getAttributes());
        }
    }

    removeCriteriaQuestionOption(question: any, option: any){
        this.incomingQuestion.setAttributes(question);
        this.incomingOption.setAttributes(option);
        this.incomingSettings.getCriterias().map((criteria: any)=>{
            this.incomingCriteria.setAttributes(criteria);
            this.incomingCriteria.getQuestions().map((criteriaQuestion: any) =>{
                this.incomingCriteriaQuestion.setAttributes(criteriaQuestion);
                if(parseFloat(this.incomingCriteriaQuestion.getControlId()) ===-18){
                    if(this.incomingCriteriaQuestion.getCntrlNum().includes(this.incomingQuestion.getCntrlNum())){
                        this.incomingCriteriaQuestionOption.setAttributes(option);
                        this.incomingCriteriaQuestion.getOptions().splice(this.service.findIndex(
                            this.incomingCriteriaQuestion.getOptions(), this.strings.getCntrlNum(),
                            this.incomingCriteriaQuestionOption.getCntrlNum()), 1);
                    }
                }
            });
        });
        this.update();
    }

    onCustomReportQuestionRemoved(question: any){
        this.incomingQuestion.setAttributes(question);
        if(this.incomingQuestion.attributes.hasOwnProperty('report_question_id')){
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setId(this.incomingQuestion.getReportQuestionId());
            this.service.httpService('post', '/entities/xx/delete',
                this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false},(response: any) => {
                    this.incomingCustomReportSelected.getQuestions().splice(this.service.findIndex(this.incomingCustomReportSelected.getQuestions(), 'report_question_id', this.incomingQuestion.getReportQuestionId()), 1);
                }, (error: any) => {
                });
        }

    }

    onTextChange(event: any, column: any){
        if(this.service.isNull(event.target.value)){
            this.incomingForm.setUnknown(column, '');
            this.update();
        }
    }

    onSelectProduct(event: MatAutocompleteSelectedEvent): void {
        this.incomingQuestion.setAttributes(event.option.value);
        if(this.currentQuestionalProducts.indexOf(event.option.value)) {
            this.currentQuestionalProducts.push(this.incomingQuestion.getAttributes());
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setSample('form.products');
            this.outgoingRequest.setEntity1(this.incomingForm.getId());
            this.outgoingRequest.setEntity2(this.incomingQuestion.getId());
            this.service.httpService('post', '/entities/sampling',
                this.outgoingRequest.getAttributes(), {ignoreLoadingBar: 'true', notify: false}, (response: any) => {}, (error: any) => {
                });
        }
        this.productElement.nativeElement.value = '';
        this.productCtrl.setValue(null);
    }

    private _filterQuestion(label: string) {
        const filterValue = label.toLowerCase();
        return this.incomingForm.getControls().filter((question: any) => JSON.stringify(question).toLowerCase().includes(filterValue));
    }

    onContextMenu(event: MouseEvent, itm: any) {
        event.preventDefault();
        this.contextMenuPosition.x = event.clientX + 'px';
        this.contextMenuPosition.y = event.clientY + 'px';
        this.contextMenu.menuData = { item: itm };
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
    }

    onQuestionSelectionChanged(event: MatAutocompleteSelectedEvent){
        this.incomingForm.setGpsReference(event.option.value.id);
        this.update();
    }


    displayFn(value: any){
        return value.label;
    }

    update(){
        // setTimeout(() => {
        //     // this.outgoingRequest.setAttributes(Object.assign({}, this.incomingForm.getAttributes()));
        //     if(parseFloat(this.formService.getSelected()[this.strings.getId()]) === parseFloat(this.incomingForm.getId())){
        //         this.outgoingRequest.setAttributes({});
        //         this.outgoingRequest.setId(this.incomingForm.getId());
        //         this.outgoingRequest.setSettings(JSON.stringify(this.incomingForm.getSettings()));
        //         this.service.httpService( 'POST',
        //             this.service.app.routes.forms.settings.update, this.outgoingRequest.getAttributes(),
        //             {ignoreLoadingBar: 'true',notify: 'false'},(response: any) => {
        //             },  (error: any) => {});
        //     }
        // });
        // // const dialogRef: any =  this.dialog.open(MessageDisplayComponent, {data : {title: '',action:'upgrade'}, width : 'auto', });
        // // dialogRef.afterClosed().subscribe((item: any) => {
        // //     if (item instanceof Object){
        // //         if (item.hasOwnProperty('response')){
        // //             if (item.response.toString().includes('successfull')){
        // //
        // //             }
        // //         }
        // //     }
        // // });

    }

    onSaveQuestionnareChanged(){
        return this.incomingForm.getSaveQuestionnaire() !== 0;
    }

    setSaveQuestionnaire(checked: any){
        setTimeout(() => {
            this.incomingForm.attributes = Object.assign(
                this.incomingForm.getAttributes(),
                {save_questionnaire: checked ? 0 : 1});
            this.formService.update(this.incomingForm, (result: any) => {
                this.notifier.notify('success', 'successfull updated');
            });
        });
    }

    getUserByUser(employee: any){
        employee = Object.assign(employee, this.userService.getUserByUser(employee));
        return employee;
    }

    addUser(){
        this.employeeMetthods.setSelected({});
        this.dialog.open(DialogEmployeesComponent, {disableClose: true, data  :
                {params: {flag: 0}}, width : '55%', height: '100%',});
    }

    getManagers(){
        return this.service.app.data.employees.data.filter((employee: any) => {
            return parseFloat(employee.role.id) === 18;});
    }
}
