import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import {AppService} from '../../../app.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Parameters} from '../../../parameters';
import {FormService} from '../../../forms/form.service';
import * as arrayBufferToAudioBuffer from 'arraybuffer-to-audiobuffer';
import {ResponseService} from '../../../forms/responses/data/response.service';
// import * as textToSpeech from '@google-cloud/text-to-speech';
// import * as speech from '@google-cloud/speech';
import * as util from 'util';
import * as fs from "fs";
import {BroadcastChannelService} from "../../../broadcast-channel.service";
import {NotifierService} from "angular-notifier";


@Component({
    selector: 'app-qrcode-scanning-invitation',
    templateUrl: './qrcode-scanning-invitation.component.html',
    styleUrls: ['./qrcode-scanning-invitatation.component.scss'],
    providers: [
    ],
})
export class QrcodeScanningInvitatationComponent implements OnInit, AfterViewInit {
    @ViewChild('elmScanner', {static: false})  scannerNativeElement: any;
    outgoingRequest: any;
    incomingCampaign: any;
    incomingClient: any;
    incomingForm: any;
    strings: any;
    incomingResult: any;
    incomingResponse: any;
    formats: any = '[\'QR_CODE\', \'EAN_13\']';
    voices: any = [];
    audioContent: any;
    audioContentResult: any;
    src: any = '';
    audioContext: any;
    formId: any;
    incomingFormSettings: any;
    urlSplit: any;
    public config: any = {
//      fps: 100,
//      isBeep: false,
//      decode: 'macintosh',
//      deviceActive: 1,
        constraints: {
            audio: false,
            video: {
                width: window.innerWidth
            }
        }
    };
    constructor(public  service: AppService,
                public  router: Router,
                public responseService: ResponseService,
                public broadcastChannelService: BroadcastChannelService,
                private notifierService: NotifierService,
                private formService: FormService,
                private activatedRoute: ActivatedRoute) {
        this.outgoingRequest = new Parameters();
        this.incomingFormSettings = new Parameters();
        this.incomingForm = new Parameters();
        this.incomingResult = new Parameters();
        this.incomingResponse = new Parameters();
        this.strings = new Parameters();
        this.incomingCampaign = new Parameters();
        this.incomingClient = new Parameters();
        this.scannerNativeElement = ElementRef;
        this.incomingFormSettings.setAttributes({});
        this.incomingCampaign.setAttributes({});
        this.incomingClient.setAttributes({});
        this.incomingForm.setAttributes({});
        this.strings.setAttributes(this.service.app.strings.params);
    }

    ngOnInit(): void {
        this.formId =  this.activatedRoute.snapshot.params.formId;
        this.formId = this.service.decrypt(this.formId);
        this.outgoingRequest.setAttributes({});
        this.outgoingRequest.setId(this.formId);
        if(this.responseService.responseSelectedCntrlNum !== null)
            this.after();
        if(Object.keys(this.formService.selected).length === 0){
            this.service.httpService('post',
                this.service.app.routes.forms.get.toString(),
                this.outgoingRequest.getAttributes(), {notify: false}, (result: any) => {
                    this.incomingForm.setAttributes(result);
                    this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
                    this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
                    this.incomingClient.setAttributes(this.incomingCampaign.getClient());
                    this.formService.setSelected(this.incomingForm.getAttributes());
                    document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingFormSettings.getBackgroundColor();
                    document.getElementsByTagName( 'body')[0].style.color = this.incomingFormSettings.getColor();
                }, (error: any) =>  {
                });
        }else{
            this.incomingForm.setAttributes(this.formService.getSelected());
            this.incomingFormSettings.setAttributes(this.incomingForm.getSettings());
            this.incomingCampaign.setAttributes(this.incomingForm.getCampaign());
            this.incomingClient.setAttributes(this.incomingCampaign.getClient());
            document.getElementsByTagName( 'body')[0].style.backgroundColor = this.incomingFormSettings.getBackgroundColor();
            document.getElementsByTagName( 'body')[0].style.color = this.incomingFormSettings.getColor();
        }
        const params: any = 'https://us-central1-texttospeech.googleapis.com/v1beta1/text:synthesize';
        const  re: any = {
            audioConfig: {
                audioEncoding: 'LINEAR16',
                effectsProfileId: [
                    'small-bluetooth-speaker-class-device'
                ],
                pitch: 0,
                speakingRate: 1
            },
            input: {
                text: 'Google Cloud Text-to-Speech enables developers to synthesize natural-sounding speech with 100+ voices, available in multiple languages and variants. It applies DeepMind’s groundbreaking research in WaveNet and Google’s powerful neural networks to deliver the highest fidelity possible. As an easy-to-use API, you can create lifelike interactions with your users, across many applications and devices.'
            },
            voice: {
                languageCode: 'en-US',
                name: 'en-US-Neural2-J'
            }
        };
    }

    camerasNotFound(e: Event) {
        // Display an alert modal here
    }

    cameraFound(e: Event) {
        // Log to see if the camera was found
    }

    scanSuccessHandler(result: any) {
        result = result.toString().replaceAll(this.strings.getEqualSlashConfirmation(), this.strings.getSlashConfirmation());
        if(!result.includes(this.strings.getAppInvitationsSlash())){
            this.responseService.responseSelectedCntrlNum = result;
            this.ngOnInit();
        }else this.validateAttendee(result);
    }


    getVoices() {
            let availableVoices: any = [];
            if(this.voices.length === 0){
              // some time the voice will not be initialized so we can call spaek with empty string
              // this will initialize the voices
              let utterance = new SpeechSynthesisUtterance("");
              speechSynthesis.speak(utterance);
              availableVoices = speechSynthesis.getVoices();
            }else {
                this.voices = availableVoices;
            }
    }

    playSound(buffer) {
        const source = this.audioContent.createBufferSource(); // creates a sound source
        source.buffer = buffer;                    // tell the source which sound to play
        source.connect(this.audioContent.destination);       // connect the source to the context's destination (the speakers)
        source.noteOn(0);                          // play the source now
    }

    ngAfterViewInit(){
       setTimeout(() =>{
            if(this.scannerNativeElement.nativeElement){
                this.scannerNativeElement.nativeElement.focus();
            }
       });
//         speechSynthesis.addEventListener('voiceschanged', () => {
//             const voices = speechSynthesis.getVoices();
//         });
//         var params: any = {
//             input: {
//                 text: 'Google'
//             },
//             audioConfig: {
//                 audioEncoding: 'LINEAR16',
//                 pitch: 0,
//                 speakingRate: 1
//             },
//             voice: {
//                 languageCode: 'en-US',
//                 name :  "en-US-News-L" ,
//                 ssmlGender: "FEMALE"
//             }
//         };
// //      name: 'en-US-Neural2-J',
//         this.audioContext = new AudioContext();
//         this.incomingResult.setAttributes({});
//        const arrayBuffer: any = Uint8Array.from(audioString).buffer;
//        context.decodeAudioData(arrayBuffer, function(buffer) {
//         }, (error: any)=>{});
//         arrayBufferToAudioBuffer(arrayBuffer, context)
//                           .then(audioBuffer => {
//         });


//         params = JSON.stringify(params);
//         var request = new XMLHttpRequest();
//         request.open('POST', 'https://content-texttospeech.googleapis.com/v1/text:synthesize?alt=json&key=AIzaSyBvl3YCf1HCaokPiYgcuhdWojTxI1490es',true);
//         request.setRequestHeader('Content-type', 'application/json')
//         request.responseType = 'json';
//         request.onload = () =>{
//             this.incomingResult.setAttributes(request.response);
//             this.incomingResult.setUnknown('audioContent', 'data:audio/x-wav;base64,' + this.incomingResult.getUnknown('audioContent'));
//             let blob: any = this.service.dataURItoBlob(this.incomingResult.getUnknown('audioContent'));
//             const arrayBuffer: any = Uint8Array.from(this.incomingResult.getUnknown('audioContent')).buffer;
//             this.outgoingRequest.setAttributes({});
//                 this.outgoingRequest.setSrc(null);
//                 this.outgoingRequest.setFlag(0);
//                 this.outgoingRequest.setCaption('');
//                 this.outgoingRequest.setOrderBy('');
//                 this.outgoingRequest.setStatus(1);
//                 this.outgoingRequest.setUrl('');
//                 this.outgoingRequest.setPath('');
//                 this.outgoingRequest.setAvatar(blob);
//                 this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
//                   this.outgoingRequest.getAttributes(), {},(result: any) => {
//                      this.incomingResult.setAttributes(result);
//                      this.service.playSoundByUrl(this.incomingResult.getSrc());
//                   }, (error: any) => {
//                   });
//
//         }
//         request.send(params);

//         this.outgoingRequest.setAttributes(params);
//         this.service.httpService('post', '/texttospeech/googleapi', params, {ignoreLoadingBar: 'true', }, (result: any) => {
//             this.audioContentResult = result.audioContent;
//             this.audioContent = new AudioContext();
//             const arr: any = Uint8Array.from(result.audioContent);
//             const data: any = arr.buffer;
//             // const blob = new Blob([arr.buffer], { type: "audio/mpeg" });
//             this.writeFile(result.audioContent).then(
//                 (value) =>{
//                 },
//                 (error) =>{ /* code if some error */ }
//              );
//
//             this.outgoingRequest.setAttributes({});
//             this.outgoingRequest.setSrc(null);
//             this.outgoingRequest.setFlag(0);
//             this.outgoingRequest.setCaption('');
//             this.outgoingRequest.setOrderBy('');
//             this.outgoingRequest.setStatus(1);
//             this.outgoingRequest.setUrl('');
//             this.outgoingRequest.setPath('');
//             this.outgoingRequest.setAvatar(this.service.dataURItoBlob(params.voice.audio));
//             this.service.httpService('post', this.service.app.routes.texttospeech.writeFile,
//               this.outgoingRequest.getAttributes(), {},(result: any) => {
//                  this.incomingResult.setAttributes(result);
//               }, (error: any) => {
//               });
// //
// //             var audio: any = new Audio('data:audio/mpeg;base64,' + result.audioContent);
// //             audio.play();
// //           var enc = new TextEncoder(); // always utf-8
// //           data = enc.encode(result.audioContent).buffer;
// //           arrayBufferToAudioBuffer(data, this.audioContent)
// //           .then(audioBuffer => {
// //             // do something with AudioBuffer
// //           });
// //           this.myFunction(data).then(
// //             (value) =>{},
// //             (error) =>{ /* code if some error */ }
// //           );
//
// //           async ()=>{
// //              const writeFile = util.promisify(fs.writeFile);
// //              await writeFile('output.mp3', result.audioContent, 'binary');
// //              console.log('Audio content written to file: output.mp3');
// //           };
//
// //          const sound: any = new Audio();
// //          sound.srcObject = result.data;
// //          sound.volume = 1;
// //          sound.play();
// //      var decoded = Utilities.base64Decode(result.audioContent, Utilities.Charset.UTF_8);
// //      var blob = Utilities.newBlob(decoded, 'audio/mpeg', "output.mp3");
// //      var res = Drive.Files.insert({'title': 'test.mp3', 'mimeType': 'audio/mpeg'}, mp3);
// //             const writeFilex = util.promisify(fs.writeFile);
//
//             // await writeFile(outputFile, result.audioContent, 'binary');
// //      console.log(`Audio content written to file: ${outputFile}`);
// //       let reader = new FileReader()
// //              reader.readAsArrayBuffer(arr.buffer)
// //              reader.onload = async () => {
// //                  let arrayBuffer = reader.result;
// //                  let audioBuffer = await this.audioContent.decodeAudioData(arrayBuffer)
// //        }
// //           this.service.httpService('post', 'https://content-texttospeech.googleapis.com/v1/text:synthesize?alt=json&key=AIzaSyBvl3YCf1HCaokPiYgcuhdWojTxI1490es', result, {ignoreLoadingBar: 'true',ignoreServerUrl:'true'}, (result: any) => {
// //                this.incomingResult.setAttributes(result);
// //           }, (error: any) =>  {
// //           });
// //          const blob = new Blob([arr.buffer], { type: "audio/mpeg" });
// //          let fileReader = new FileReader();
// //          let arrayBuffer;
// //
// //          fileReader.onloadend = () => {
// //              arrayBuffer = fileReader.result;
// //              arrayBufferToAudioBuffer(arrayBuffer, this.audioContent)
// //                    .then(audioBuffer => {
// //                      // do something with AudioBuffer
// //                    });
// //          };
// //
// //          fileReader.readAsArrayBuffer(blob);
// //          this.src = URL.createObjectURL(blob);
// //          this.playSound(arr.buffer);
// //                 this.audioContent.decodeAudioData(result.audioContent, (buffer: any) =>{
// //                 }, (err) =>{ console.log(err); });
//
//
//
//         }, (error: any) =>  {
//         });
//         if ('speechSynthesis' in window) {
//             const synthesis = speechSynthesis;
//             // Regex to match all English language tags e.g en, en-US, en-GB
//             const langRegex = /^en(-[a-z]{2})?$/i;
//
//             // Get the available voices and filter the list to only have English speakers
//             const voices = synthesis
//                 .getVoices()
//                 .filter((voice) => langRegex.test(voice.lang));
//
//             // Log the properties of the voices in the list
//             voices.map((voice) => {
//                 alert({
//                     name: voice.name,
//                     lang: voice.lang,
//                     uri: voice.voiceURI,
//                     local: voice.localService,
//                     default: voice.default,
//                 });
//             });
//         } else {
//             console.log('Text-to-speech not supported.');
//         }
//  this.validateAttendee();
    }

    async  myFunction(data: any){
        const context: any = new AudioContext();
        const buffer: any = await context.decodeAudioData(data);
        const source: any = context.createBufferSource();
        source.buffer = buffer;
        source.connect(context.destination);
        source.start();
        return 'Hello';

    }

    onScanning(event: any, elmScanner: any){
        if(!this.service.isNull(event.target.value)){
            let scanningText:any = event.target.value;
            elmScanner.value = '';
            this.scanSuccessHandler(scanningText);
        }
    }


    async  writeFile(responseAudioContent: any){
        const outputFile: any = 'output.wav';
        const writeFile = util.promisify(fs.writeFile);
        await writeFile(outputFile, responseAudioContent, 'binary');
        console.log(`Audio content written to file: ${outputFile}`);
    }


    after(){
        this.incomingForm.setAttributes(this.formService.getSelected());
        if(this.incomingForm.getAttributes().hasOwnProperty('id')){
            this.outgoingRequest.setAttributes({});
            this.outgoingRequest.setCntrlNum(this.responseService.responseSelectedCntrlNum);
            this.outgoingRequest.setFormId(this.incomingForm.getId());
         // this.outgoingRequest.setForm(JSON.stringify(this.incomingForm.getAttributes()));
            this.service.playSoundWait(() => {});
            this.service.httpService('post',
                this.service.app.routes.invitations.qrcode.validation.toString(),
                this.outgoingRequest.getAttributes(), {}, (result: any) => {
                this.incomingResult.setAttributes(result);
                this.responseService.responseSelectedCntrlNum = null;
                if (parseFloat(this.incomingResult.getStatus()) === 200){
                    this.incomingResponse.setAttributes(Object.assign({}, this.incomingResult.getResponse()));
                    this.incomingResponse.setForm(this.incomingForm.getAttributes());
                    this.service.response = this.incomingResponse.getAttributes();
                    this.service.audioSound1.pause();
                    this.service.audioSound1.currentTime = 0;
                    this.router.navigateByUrl(this.service.app.routes.app.welcomeScreen);
                }else this.service.notValidCard();
            }, (error: any) =>  {
                    this.service.repeatScan();
                    this.responseService.responseSelectedCntrlNum = null;
                    this.notifierService.notify(this.strings.getSuccess(), this.strings.getInternetDisconnected());
                    this.service.audioSound1.pause();
                    this.service.audioSound1.currentTime = 0;
            });
        }else this.after();
    }


    validateAttendee(result: any){
        try{
            result = result.toString().split(this.strings.getAppInvitationsSlash());
            if(result.length > 0){
              result = result[1];
              result = result.split('/')[0];
              this.router.navigateByUrl('app/main/'+result+'/getrequest');
            }else this.service.notValidCard();
        }catch(e){ this.service.notValidCard();
        }
    }

}
